<template>
  <div
    v-if="this.apiKeys"
    class="bg-gray-100 shadow overflow-hidden sm:rounded-lg"
  >
    <div
      @click="this.toggleVisibility"
      class="flex justify-between items-center px-4 py-5 sm:px-6"
    >
      <h3 class="text-lg leading-6 font-bold text-gray-900">Clés API</h3>
      <div class="w-10">
        <ChevronDownIcon v-if="!this.isVisible" />
        <ChevronUpIcon v-else />
      </div>
    </div>
    <div v-if="this.isVisible" class="flex flex-col">
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Id") }}
                  </th>

                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Enabled") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Scopes") }}
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 text-xs">
                <tr
                  v-for="(apiKey, index) in this.apiKeys"
                  :key="index"
                  v-bind:class="'hover:bg-gray-100'"
                >
                  <td class="px-3 py-3.5 font-bold">
                    {{ apiKey.id }}
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-bind:style-to-add="
                        apiKey.enabled ? 'bg-green-200' : 'bg-red-200'
                      "
                      v-bind:value="apiKey.enabled"
                    />
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-for="scope in apiKey.scopes"
                      v-bind:key="scope"
                      v-bind:style-to-add="'bg-green-200'"
                      v-bind:value="scope"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronUpIcon, ChevronDownIcon, FieldSpan },
  props: ["apiKeys"],
  data() {
    return {
      isVisible: false, // Initial visibility state
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible; // Toggle the visibility state
    },
  },
};
</script>
