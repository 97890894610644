<template>
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1
        class="text-3xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900"
      >
        Challenges
      </h1>
    </div>
    <span class="relative z-0 inline-flex shadow-sm rounded-md space-x-3">
      <button
        class="relative inline-flex rounded-full text-button-font-primary bg-primary uppercase p-2 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
        @click="this.openModal = true"
      >
        <PlusIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </span>
  </div>

  <CreateChallengeModal
    v-bind:open-modal="this.openModal"
    @close="this.openModal = false"
    @challengeCreated="this.retrieveChallenges"
  />

  <ChallengeListContent v-bind:challenges="this.challenges" />
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import { PlusIcon } from "@heroicons/vue/solid";
import CreateChallengeModal from "@/views/challenges/create/CreateChallengeModal.vue";
import ChallengeListContent from "@/views/challenges/list/ChallengeListContent.vue";

export default {
  components: {
    ChallengeListContent,
    CreateChallengeModal,
    PlusIcon,
  },

  data() {
    return {
      challenges: [],
      openModal: false,
    };
  },

  watch: {
    openModal(newValue) {
      if (newValue === false) {
        this.retrieveChallenges();
      }
    },
  },

  async mounted() {
    await this.retrieveChallenges();
  },

  methods: {
    retrieveChallenges: async function () {
      const response = await BackofficeService.getChallenges();
      this.challenges = response.data.challenges;
    },
  },
};
</script>
