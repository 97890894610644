<template>
  <div>
    <h1 class="font-bold pt-6">
      Rapport assureur pour les polices d'assurances
    </h1>

    <div date-rangepicker class="mt-6 flex items-center">
      <div class="relative">
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          v-model="startDate"
          name="start"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
          placeholder="Select date start"
        />
      </div>
      <span class="mx-4 text-gray-500">to</span>
      <div class="relative">
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          v-model="endDate"
          name="end"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
          placeholder="Select date end"
        />
      </div>
    </div>
    <PlanSaleFilter @updatedVertical="this.updateVertical" />
    <button
      type="button"
      class="mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary uppercase transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none"
      @click="this.generatePlanSalesInsuranceReport"
    >
      Générer
    </button>
  </div>
</template>

<script>
import { isObjectValid, isStringValid } from "@/utils/validation";
import BackofficeService from "@/api/services/backoffice";
import PlanSaleFilter from "@/views/report/utils/PlanSaleFilter.vue";

export default {
  components: { PlanSaleFilter },
  data: () => {
    return {
      startDate: null,
      endDate: null,
      selectedInsurerId: null,
      selectedContractCategory: null,
    };
  },

  methods: {
    generatePlanSalesInsuranceReport: async function () {
      if (
        isStringValid(this.startDate) &&
        isStringValid(this.endDate) &&
        isObjectValid(this.selectedInsurerId) &&
        isObjectValid(this.selectedContractCategory)
      ) {
        const response = await BackofficeService.generateInsurerReport(
          this.startDate,
          this.endDate,
          this.selectedInsurerId,
          this.selectedContractCategory
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
      }
    },

    updateVertical: function (insurerId, contractCategory) {
      this.selectedInsurerId = insurerId;
      this.selectedContractCategory = contractCategory;
    },
  },
};
</script>
