<template>
  <div class="mt-4">
    <Listbox class="w-1/4" as="div" v-model="selected">
      <ListboxLabel class="block text-sm font-medium text-gray-700"
        >Insurer</ListboxLabel
      >
      <div class="relative mt-1">
        <ListboxButton
          v-if="selected !== null && selected !== undefined"
          class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
        >
          <span class="block truncate">{{ selected.name }}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              as="template"
              v-for="insurer in this.insurers"
              :key="insurer.id"
              :value="insurer"
              v-slot="{ active, selected }"
            >
              <li
                :class="[
                  active ? 'text-white bg-primary' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                  >{{ insurer.name }}</span
                >

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-primary-dark',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <div class="mt-4" v-if="selected">
      <Listbox class="w-1/4" as="div" v-model="selectedContract">
        <ListboxLabel class="block text-sm font-medium text-gray-700"
          >Contract Category</ListboxLabel
        >
        <div class="relative mt-1">
          <ListboxButton
            v-if="selectedContract !== null && selectedContract !== undefined"
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
          >
            <span class="block truncate">{{ selectedContract }}</span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronDownIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                as="template"
                v-for="contractCategory in this.selected.contractCategories"
                :key="contractCategory"
                :value="contractCategory"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    active ? 'text-white bg-primary' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                    >{{ contractCategory }}</span
                  >

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-primary-dark',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
  </div>
</template>
<script>
import BackofficeService from "@/api/services/backoffice";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ListboxOption,
    ListboxOptions,
    ListboxButton,
    ListboxLabel,
    Listbox,
    CheckIcon,
    ChevronDownIcon,
  },

  data: () => {
    return {
      insurers: [],
      selected: null,
      selectedContract: null,
    };
  },

  watch: {
    selected: function (newSelectedInsurer) {
      this.$emit(
        "updatedVertical",
        newSelectedInsurer.id,
        this.selectedContract
      );
    },

    selectedContract: function (newSelectedContract) {
      this.$emit("updatedVertical", this.selected.id, newSelectedContract);
    },
  },

  mounted() {
    this.getInsurers();
  },

  methods: {
    getInsurers: async function () {
      const response = await BackofficeService.getInsurers();
      this.insurers = response.data.insurers;
      this.selected = this.insurers[0];
      this.selectedContract = this.selected.contractCategories[0];
    },
  },
};
</script>
