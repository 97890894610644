<template>
  <div v-if="this.store">
    <StoreGeneralInformation
      v-bind:general-informations="this.store.generalInformations"
      v-bind:sales-field-managers="this.store.salesFieldManagers"
      @update:selectedSalesFieldManager="onSalesFieldManagerChange"
    />
    <StoreBillingInformation
      class="mt-10"
      v-bind:billing-configuration="this.store.billingConfiguration"
    />
    <StoreDiscountInformation
      class="mt-10"
      v-bind:discount="this.store.discount"
      v-bind:challenges="this.store.challenges"
      v-bind:store-id="this.store.generalInformations.id"
      @discountUpdated="this.getStore"
      @challengeUpdated="this.getStore"
    />
    <StoreInsuranceOffers
      class="mt-10"
      v-bind:insurance-offers="this.store.insuranceOffers"
    />
    <StoreMatchedContracts
      class="mt-10"
      v-bind:matched-contracts="this.store.matchedContracts"
    />
    <StoreUsersInformation
      class="mt-10"
      :users="this.store.users"
      :store-id="this.store.generalInformations.id"
    />
    <SellersInformation
      class="mt-10"
      :sellers="this.store.sellers"
      @getSellers="getSellers"
    />
    <StoreTechnicalConfiguration
      class="mt-10"
      v-bind:technical-configuration="this.store.technicalConfiguration"
    />
    <StoreApiKeysInformation
      class="mt-10"
      v-bind:api-keys="this.store.apiKeys"
    />
  </div>
</template>

<script>
import StoreGeneralInformation from "@/views/stores/details/StoreGeneralInformation.vue";
import StoreInsuranceOffers from "@/views/stores/details/StoreInsuranceOffers.vue";
import StoreMatchedContracts from "@/views/stores/details/StoreMatchedContracts.vue";
import StoreDiscountInformation from "@/views/stores/details/StoreDiscountInformation.vue";
import StoreBillingInformation from "@/views/stores/details/StoreBillingInformation.vue";
import SellersInformation from "@/views/stores/details/SellersInformation.vue";
import StoreTechnicalConfiguration from "@/views/stores/details/StoreTechnicalConfiguration.vue";
import StoreApiKeysInformation from "@/views/stores/details/StoreApiKeysInformation.vue";
import StoreUsersInformation from "@/views/stores/details/StoreUsersInformation.vue";

import BackofficeService from "@/api/services/backoffice.js";

export default {
  components: {
    StoreApiKeysInformation,
    StoreTechnicalConfiguration,
    SellersInformation,
    StoreUsersInformation,
    StoreBillingInformation,
    StoreDiscountInformation,
    StoreMatchedContracts,
    StoreInsuranceOffers,
    StoreGeneralInformation,
  },

  data() {
    return {
      store: null,
    };
  },

  async beforeMount() {
    await this.getStore();
  },

  methods: {
    getStore: async function () {
      const storeId = this.$route.params.id;
      if (storeId) {
        const response = await BackofficeService.getStore(storeId);
        this.store = response.data;
      }
    },
    onSalesFieldManagerChange: async function (salesFieldManagerId) {
      const storeId = this.$route.params.id;
      if (storeId) {
        await BackofficeService.updateStoreSalesFieldManager(
          storeId,
          salesFieldManagerId
        );
      }
    },
    getSellers() {
      this.getStore();
    },
  },
};
</script>
