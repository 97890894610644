<template>
  <div>
    <SearchBar
      v-bind:current-page="currentPage"
      v-bind:placeholder="$t('OrganisationSearch')"
      v-bind:initial-keywords="keywords"
      v-bind:available-filters="availableFilters"
      v-bind:selected-filters="selectedFilters"
      @updateKeywordSearch="updateKeywordSearch"
      @updateFilterSearch="updateFilterSearch"
    />
    <div class="mt-10 relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr class="hidden md:table-row">
            <th scope="col" class="px-6 py-3">{{ $t("Id") }}</th>
            <th scope="col" class="px-6 py-3">{{ $t("Name") }}</th>
            <th scope="col" class="px-6 py-3">{{ $t("Channel") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="organization in organizations"
            v-bind:key="organization.id"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 cursor-pointer"
            @click="redirectToOrganizationDetailsPage(organization.id)"
          >
            <td class="px-6 py-4 hidden md:table-cell">
              {{ organization.id }}
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              {{ organization.name }}
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              {{ $t(organization.source) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      v-bind:currentPage="currentPage"
      v-bind:pages="pages"
      v-bind:startIdx="startIdx"
      v-bind:endIdx="endIdx"
      v-bind:total="totalNumberOfOrganizations"
      v-on:changePage="handleChangePage"
      v-if="pages.length > 1"
    />
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import Pagination from "@/components/utils/Pagination.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import { mapMutations, mapState } from "vuex";

export default {
  components: { SearchBar, Pagination },

  async mounted() {
    await this.retrieveOrganizations(this.searchParams);
  },

  data() {
    return {
      organizations: [],
      currentPage: 1,
      keywords: null,
      totalNumberOfOrganizations: 1,
      availableFilters: {
        sources: [],
      },
      selectedFilters: {
        sources: [],
      },
      pageSize: 1,
    };
  },

  computed: {
    ...mapState("backoffice", ["isLoading"]),

    startIdx: function () {
      return (this.currentPage - 1) * this.pageSize;
    },
    endIdx: function () {
      return Math.min(
        this.startIdx + this.pageSize,
        this.totalNumberOfOrganizations
      );
    },
    pages: function () {
      if (this.isLoading || this.totalNumberOfOrganizations < this.pageSize) {
        return [];
      }
      return [
        ...Array(
          Math.ceil(this.totalNumberOfOrganizations / this.pageSize)
        ).keys(),
      ].map((e) => e + 1);
    },
    searchParams: function () {
      return {
        page: this.currentPage,
        keywords: this.keywords,
        sources: this.selectedFilters.sources,
      };
    },
  },

  methods: {
    ...mapMutations("backoffice", ["setIsLoading"]),

    retrieveOrganizations: async function (params) {
      let response = null;
      let timeout = null;

      try {
        timeout = setTimeout(() => {
          this.setIsLoading(true);
        }, 200);
        response = await BackofficeService.getOrganizations(params);
        this.organizations = response.data.organizations;
        this.totalNumberOfOrganizations =
          response.data.total_number_of_organizations;
        this.availableFilters = response.data.filters;
        this.pageSize = response.data.page_size;
      } catch (error) {
        console.log(error);
      } finally {
        clearTimeout(timeout);
        this.setIsLoading(false);
      }
    },

    handleChangePage: function (page) {
      this.currentPage = page;
      this.retrieveOrganizations(this.searchParams);
    },

    updateKeywordSearch: function (keywords) {
      this.keywords = keywords;
      this.handleChangePage(1);
    },

    updateFilterSearch: function (filterName, filterOptions) {
      this.selectedFilters[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    redirectToOrganizationDetailsPage: function (organizationId) {
      this.$router.push(`/organizations/${organizationId}`);
    },
  },
};
</script>
