<template>
  <div
    v-if="Object.keys(this.authorizedActions).length > 0"
    class="bg-gray-100 p-5 mt-5"
  >
    <p class="block text-sm font-medium leading-6 text-gray-900">
      {{ this.name.charAt(0).toUpperCase() + this.name.slice(1) }} Notification
    </p>
    <div>
      <p class="text-sm text-gray-500">
        How do you prefer to send notifications?
      </p>
      <div v-if="name === 'merchant'">
        <label class="mt-5 block text-sm font-medium leading-6 text-gray-900"
          >Claim manager</label
        >
        <input
          type="text"
          v-bind:name="this.name + '-claim-manager'"
          v-bind:id="this.name + '-claim-manager'"
          class="mt-1 hidden focus:ring-primary focus:border-primary w-full rounded-none rounded-md sm:block sm:text-xs border-gray-300 py-1"
          v-bind:placeholder="'Claim manager'"
          v-model="this.selectedClaimManager"
        />
      </div>
      <fieldset class="mt-4 bg-gray-200 p-4">
        <legend class="sr-only">Notification method</legend>
        <div
          class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0"
        >
          <div
            class="flex items-center"
            v-for="action in this.authorizedActions"
            v-bind:key="action.name"
          >
            <input
              :name="this.name + '-notification-mode-' + action.id"
              type="radio"
              class="h-4 w-4 rounded border-gray-300 text-primary-dark focus:ring-primary"
              :value="action"
              v-model="this.selectedAction"
            />
            <label
              class="ml-3 block text-sm font-medium leading-6 text-gray-900"
              >{{ action.name }}</label
            >
          </div>
        </div>
      </fieldset>
      <div v-if="this.displayPredefinedActions" class="mt-5">
        <fieldset class="mt-4">
          <legend class="sr-only">Predefined actions</legend>
          <div
            class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0"
          >
            <div
              class="flex items-center"
              v-for="predefinedAction in this.predefinedActions"
              v-bind:key="predefinedAction"
            >
              <input
                :name="this.name + '-predefined-actions'"
                type="radio"
                class="h-4 w-4 border-gray-300 text-primary-dark focus:ring-primary"
                :value="predefinedAction"
                v-model="chosenPredefinedAction"
              />
              <label
                class="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >{{ predefinedAction }}</label
              >
            </div>
          </div>
        </fieldset>
      </div>
      <div v-if="this.displayTextArea" class="mt-5">
        <label
          for="comment"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Add your comment</label
        >
        <div class="mt-2">
          <textarea
            rows="4"
            name="comment"
            id="comment"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            v-model="this.textAreaContent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isObjectValid } from "@/utils/validation";

export default {
  props: ["name", "predefinedActions", "selectedStatus", "claim"],

  watch: {
    chosenPredefinedAction: function () {
      this.updateNotification();
    },
    textAreaContent: function () {
      this.updateNotification();
    },
    selectedAction: function () {
      if (this.selectedAction.id === null) {
        this.clearFormContent();
        this.updateNotification();
      }
    },
    currentActionSelected: function () {
      this.clearFormContent();

      if (this.authorizedActionIds().includes(this.currentActionSelected.id)) {
        switch (this.currentActionSelected.id) {
          case "custom_action_required":
            this.displayPredefinedActions = false;
            this.displayTextArea = true;
            break;
          case "predefined_action_required":
            this.displayPredefinedActions = true;
            this.displayTextArea = false;
            break;
          case "no_action_required":
            this.displayPredefinedActions = false;
            this.displayTextArea = true;
            break;
          default:
            this.displayPredefinedActions = false;
            this.displayTextArea = false;
        }
      } else {
        this.displayPredefinedActions = false;
        this.displayTextArea = false;
      }
    },
    selectedStatus: function () {
      this.clearFormContent();
    },
    selectedClaimManager: function (newValue) {
      this.$emit("updateClaimManager", newValue);
    },
  },

  mounted: function () {
    this.selectedClaimManager = this.claim.storeManagerEmail;
  },

  computed: {
    selectedClaimManager: {
      get() {
        return this.claimManager;
      },
      set(email) {
        this.claimManager = email;
      },
    },
    selectedAction: {
      get() {
        return this.currentActionSelected;
      },
      set(actionSelected) {
        this.currentActionSelected = actionSelected;
      },
    },
    possibleActions: {
      get() {
        return {
          custom_action: {
            id: "custom_action_required",
            name: "Custom action",
            valid_statuses: ["to_be_approved", "pending", "processing"],
          },
          predefined_action: {
            id: "predefined_action_required",
            name: "Predefined action",
            valid_statuses: ["to_be_approved", "pending", "processing"],
          },
          information_notification: {
            id: "no_action_required",
            name: "Information notification",
            valid_statuses: [
              "to_be_approved",
              "refused",
              "pending",
              "processing",
              "cancelled",
            ],
          },
          nothing: {
            id: null,
            name: "Nothing",
            valid_statuses: [
              "to_be_approved",
              "refused",
              "pending",
              "processing",
              "cancelled",
            ],
          },
        };
      },
    },
    authorizedActions: {
      get() {
        const possibleActions = this.possibleActions;
        return Object.fromEntries(
          Object.entries(possibleActions).filter(([key]) =>
            possibleActions[key].valid_statuses.includes(this.selectedStatus)
          )
        );
      },
    },
  },

  data: () => {
    return {
      displayPredefinedActions: false,
      displayTextArea: false,
      currentActionSelected: null,
      textAreaContent: null,
      chosenPredefinedAction: null,
      claimManager: null,
    };
  },

  methods: {
    authorizedActionIds: function () {
      return Object.values(this.authorizedActions).map((value) => value.id);
    },
    clearFormContent: function () {
      this.chosenPredefinedAction = null;
      this.textAreaContent = null;
    },
    updateNotification: function () {
      const notificationData =
        isObjectValid(this.textAreaContent) ||
        isObjectValid(this.chosenPredefinedAction)
          ? {
              notification_note: this.displayTextArea
                ? this.textAreaContent
                : null,
              predefined_action_type: this.displayPredefinedActions
                ? this.chosenPredefinedAction
                : null,
              action_type: this.currentActionSelected.id,
            }
          : null;
      this.$emit("update", notificationData);
    },
  },
};
</script>
