<template>
  <div>
    <Notifications />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import PublicLayout from "@/layouts/PublicLayout.vue";

const defaultLayout = "public-layout";
import BackofficeLayout from "./layouts/BackofficeLayout.vue";
import Notifications from "./components/notification/Notifications.vue";

export default {
  components: {
    BackofficeLayout,
    PublicLayout,
    Notifications,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
};
</script>
