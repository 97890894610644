<template>
  <div class="bg-gray-100 shadow overflow-hidden sm:rounded-lg">
    <div
      @click="this.toggleVisibility"
      class="flex justify-between items-center px-4 py-5 sm:px-6 cursor-pointer"
    >
      <h3 class="text-lg leading-6 font-bold text-gray-900">Vendeurs</h3>
      <div class="w-10">
        <ChevronDownIcon v-if="!this.isVisible" />
        <ChevronUpIcon v-else />
      </div>
    </div>
    <div v-if="this.isVisible" class="flex flex-col">
      <p v-if="!this.sellers" class="bg-gray-50 p-2">
        {{ $t("No sellers") }}
      </p>
      <div v-else class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Id") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Name") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Email") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Phone") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  />
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 text-xs">
                <tr v-for="(seller, index) in this.sellers" :key="index">
                  <td class="px-3 py-3.5">
                    {{ seller.id }}
                  </td>
                  <td class="px-3 py-3.5">
                    <div class="flex items-center">
                      <div class="h-full w-0.5 bg-primary"></div>
                      {{ seller.name }}
                    </div>
                  </td>
                  <td class="px-3 py-3.5">
                    <div class="flex items-center">
                      <div class="h-full w-0.5 bg-primary"></div>
                      {{ seller.email }}
                    </div>
                  </td>
                  <td class="px-3 py-3.5">
                    <div class="flex items-center">
                      <div class="h-full w-0.5 bg-primary"></div>
                      {{ seller.phone }}
                    </div>
                  </td>
                  <td class="px-3 py-3.5">
                    <PencilIcon
                      class="h-5 cursor-pointer"
                      @click="() => openUpdateModal(seller.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <SellerUpdate
      :isOpen="this.isUpdateSellerModalOpen"
      :selectedSeller="this.selectedSeller"
      @closeSellerUpdate="isUpdateSellerModalOpen = false"
      @getSellers="getSellers"
    />
  </div>
</template>

<script>
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
} from "@heroicons/vue/outline";
import SellerUpdate from "@/views/stores/details/sellers/SellerUpdate.vue";

export default {
  components: { ChevronUpIcon, ChevronDownIcon, PencilIcon, SellerUpdate },
  props: ["sellers"],

  data() {
    return {
      isVisible: false,
      isUpdateSellerModalOpen: false,
      selectedSeller: {},
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    openUpdateModal(sellerId) {
      this.selectedSeller = this.sellers.find(
        (seller) => seller.id === sellerId
      );
      this.isUpdateSellerModalOpen = true;
    },
    getSellers() {
      this.$emit("getSellers");
    },
  },
};
</script>
