<template>
  <div
    v-if="organizationInformations"
    class="bg-white shadow sm:rounded-lg p-4"
  >
    <h3 class="px-4 text-lg leading-6 text-gray-900 font-bold">
      {{ organizationInformations.business_name }}
    </h3>
    <div>
      <dl class="px-4 py-5 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-8">
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Id") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FieldSpan
              v-bind:style-to-add="'bg-purple-600 text-white'"
              v-bind:value="organizationInformations.id"
            />
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Name") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ organizationInformations.name }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Business name") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ organizationInformations.business_name }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Invoice address") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ organizationInformations.address }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Registration number") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ organizationInformations.registration_number }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Network type") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ organizationInformations.network_type }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Revenue range") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ organizationInformations.revenue_range }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Total points of sale") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ organizationInformations.total_points_of_sale }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Invoice level") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ organizationInformations.invoice_level }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Source") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ $t(organizationInformations.source) }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Logo url") }}
          </dt>
          <dd
            v-if="isStringValid(organizationInformations.logo_url)"
            class="mt-1 text-sm text-gray-900 cursor-pointer hover:underline"
            @click="
              copyToClipboard(
                organizationInformations.logo_url,
                toggleCopiedLogoUrlNotice
              )
            "
          >
            <span v-if="logoUrlCopied">
              {{ $t("Link copied to clipboard!") }}
            </span>
            <span v-else>{{ $t("Copy link to clipboard") }}</span>
          </dd>
          <dd v-else class="mt-1 text-sm text-gray-900">-</dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { isStringValid } from "@/utils/validation";

export default {
  components: { FieldSpan },

  props: {
    organizationInformations: null,
  },

  data() {
    return {
      logoUrlCopied: false,
    };
  },

  methods: {
    isStringValid,

    copyToClipboard(text, toggleNotice) {
      navigator.clipboard.writeText(text);
      toggleNotice();
    },

    toggleCopiedLogoUrlNotice() {
      this.logoUrlCopied = true;
      setTimeout(() => {
        this.logoUrlCopied = false;
      }, 2000);
    },
  },
};
</script>
