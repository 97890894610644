<template>
  <div class="pt-6 md:pt-0 relative overflow-x-auto">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead
        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
      >
        <tr class="hidden md:table-row">
          <th scope="col" class="px-6 py-3">Titre</th>
          <th scope="col" class="px-6 py-3">Type</th>
          <th scope="col" class="px-6 py-3">Code</th>
          <th scope="col" class="px-6 py-3">Nombre de mois</th>
          <th scope="col" class="px-6 py-3">Activé</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="discount in this.discounts"
          v-bind:key="discount.id"
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
        >
          <th
            scope="row"
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            {{ discount.title }}
            <div class="flex flex-col">
              <span class="md:hidden font-light">{{
                $t(discount.discountType)
              }}</span>
              <span class="md:hidden font-light"
                >Nombre de mois : {{ discount.offeredMonths }}</span
              >
            </div>
          </th>
          <td class="px-6 py-4 hidden md:table-cell">
            {{ $t(discount.discountType) }}
          </td>
          <td class="px-6 py-4 hidden md:table-cell">
            {{ discount.code }}
          </td>
          <td class="px-6 py-4 hidden md:table-cell">
            {{ discount.offeredMonths }}
          </td>
          <td class="px-6 py-4 hidden md:table-cell">
            {{ $t(`${discount.enabled}`) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["discounts"],
};
</script>
