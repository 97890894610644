<template>
  <div>
    <FieldSpan
      v-bind:style-to-add="
        getApprovalStatusFieldDisplayStyle(product.approvalStatus)
      "
      v-bind:value="product.approvalStatus"
    />
    <FieldSpan
      class="ml-3"
      v-bind:style-to-add="
        getContractStatusFieldDisplayStyle(product.contractStatus)
      "
      v-bind:value="product.contractStatus"
    />
    <FieldSpan
      class="ml-3"
      v-bind:style-to-add="getEnabledFieldDisplayStyle(product.enabled)"
      v-bind:value="product.enabled ? 'Published' : 'Not Published'"
    />
    <FieldSpan
      class="ml-3"
      v-bind:style-to-add="getSourceFieldDisplayStyle(product.source)"
      v-bind:value="product.source"
    />
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";

export default {
  components: {
    FieldSpan,
  },

  props: ["product"],

  methods: {
    getEnabledFieldDisplayStyle: function (isEnabled) {
      return isEnabled
        ? "bg-green-100 text-green-800"
        : "bg-red-100 text-red-800";
    },
    getSourceFieldDisplayStyle: function (source) {
      return source === "offline"
        ? "bg-blue-100 text-blue-800"
        : "bg-pink-100 text-pink-800";
    },
    getApprovalStatusFieldDisplayStyle: function (status) {
      let result;
      switch (status) {
        case "to_be_approved":
          result = "bg-yellow-100 text-yellow-800";
          break;
        case "approved":
          result = "bg-green-100 text-green-800";
          break;
        case "refused":
          result = "bg-red-100 text-red-800";
          break;
        default:
          result = "bg-blue-100 text-blue-800";
      }
      return result;
    },

    getContractStatusFieldDisplayStyle: function (status) {
      let result;
      switch (status) {
        case "pending":
          result = "bg-yellow-100 text-yellow-800";
          break;
        case "matched":
          result = "bg-green-100 text-green-800";
          break;
        case "non_warrantable":
          result = "bg-red-100 text-red-800";
          break;
        default:
          result = "bg-blue-100 text-blue-800";
      }
      return result;
    },
  },
};
</script>
