<template>
  <div class="border-b border-gray-900/10 pb-10">
    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="storeManagerFirstName"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Prénom</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="storeManagerFirstName"
          name="storeManagerFirstName"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          v-model="this.storeManager.firstName"
        />
        <ErrorMessage
          name="storeManagerFirstName"
          class="mt-2 text-sm text-red-600"
        />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="storeManagerLastName"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Nom</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="storeManagerLastName"
          name="storeManagerLastName"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          v-model="this.storeManager.lastName"
        />
        <ErrorMessage
          name="storeManagerLastName"
          class="mt-2 text-sm text-red-600"
        />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="storeManagerEmail"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Email</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="storeManagerEmail"
          name="storeManagerEmail"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          v-model="this.storeManager.email"
        />
        <ErrorMessage
          name="storeManagerEmail"
          class="mt-2 text-sm text-red-600"
        />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="storeManagerPassword"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Mot de passe</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="storeManagerPassword"
          name="storeManagerPassword"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          v-model="this.storeManager.password"
        />
        <ErrorMessage
          name="storeManagerPassword"
          class="mt-2 text-sm text-red-600"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";

export default {
  components: {
    ErrorMessage,
    Field,
  },

  data() {
    return {
      storeManager: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
    };
  },

  watch: {
    storeManager: {
      deep: true,
      handler(newValue) {
        this.$emit("update:storeManager", newValue);
      },
    },
  },
};
</script>
