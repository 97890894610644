<template>
  <div>
    <Menu
      v-if="this.isPendingPayment"
      as="div"
      class="relative inline-block text-left"
    >
      <div>
        <MenuButton
          class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          <FieldSpan
            v-bind:style-to-add="
              getPaymentStatusFieldDisplayStyle(invoice.paymentStatus)
            "
            v-bind:value="$t(invoice.paymentStatus)"
          />
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute overflow-y-auto right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto"
        >
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <button
                @click="this.markInvoiceAsPaid(this.invoice)"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  {{ $t("Mark as paid") }}
                  <CheckIcon
                    class="h-5 w-5 ml-2 text-green-400"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
    <FieldSpan
      v-else
      v-bind:style-to-add="
        getPaymentStatusFieldDisplayStyle(invoice.paymentStatus)
      "
      v-bind:value="$t(invoice.paymentStatus)"
    />
  </div>
</template>
<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import BackofficeService from "@/api/services/backoffice";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    CheckIcon,
    FieldSpan,
  },

  props: ["invoice"],

  computed: {
    isPendingPayment: function () {
      return this.invoice.paymentStatus === "pending_payment";
    },
  },

  methods: {
    getPaymentStatusFieldDisplayStyle: function (paymentStatus) {
      switch (paymentStatus) {
        case "pending_payment":
          return "bg-yellow-100 text-yellow-800";
        case "paid":
          return "bg-green-100 text-green-800";
        default:
          return "bg-blue-100 text-blue-800";
      }
    },

    markInvoiceAsPaid: async function (invoice) {
      await BackofficeService.markInvoiceAsPaid(invoice);
      this.$emit("invoicePaid");
    },
  },
};
</script>
