<template>
  <div class="relative flex items-start">
    <div class="flex h-6 items-center">
      <input
        v-model="marketingDetailDefaultConfiguration"
        id="default"
        aria-describedby="default-marketing-details"
        name="default"
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-primary-dark"
      />
    </div>
    <div class="ml-3 text-sm leading-6">
      <label for="default" class="font-medium text-gray-900"
        >Configuration par défaut</label
      >
    </div>
  </div>
  <div
    class="mt-4 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0"
    v-if="!marketingDetailDefaultConfiguration"
  >
    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="backgroundColor"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Backgronud color</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="backgroundColor"
          name="backgroundColor"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Couleur du fond"
          v-model="this.marketingDetails.backgroundColor"
        />
        <ErrorMessage
          name="backgroundColor"
          class="mt-2 text-sm text-red-600"
        />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="textColor"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Text color</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="textColor"
          name="textColor"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Couleur du texte"
          v-model="this.marketingDetails.textColor"
        />
        <ErrorMessage name="textColor" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="backgroundImageUrl"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Background image url</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="backgroundImageUrl"
          name="backgroundImageUrl"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Url de l'image"
          v-model="this.marketingDetails.backgroundImageUrl"
        />
        <ErrorMessage
          name="backgroundImageUrl"
          class="mt-2 text-sm text-red-600"
        />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="buttonBackgroundColor"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Button background color</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="buttonBackgroundColor"
          name="buttonBackgroundColor"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Couleur background bouton"
          v-model="this.marketingDetails.buttonBackgroundColor"
        />
        <ErrorMessage name="buttonColor" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="buttonColor"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Button color</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="buttonColor"
          name="buttonColor"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Couleur du bouton"
          v-model="this.marketingDetails.buttonColor"
        />
        <ErrorMessage name="buttonColor" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="logoUrl"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Logo url</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="logoUrl"
          name="logoUrl"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Url du logo pour la page si différent de celui du store"
          v-model="this.marketingDetails.logoUrl"
        />
        <ErrorMessage name="logoUrl" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="iconBackgroundColor"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Icon background color</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="iconBackgroundColor"
          name="iconBackgroundColor"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Couleur du background des icônes"
          v-model="this.marketingDetails.iconBackgroundColor"
        />
        <ErrorMessage
          name="iconBackgroundColor"
          class="mt-2 text-sm text-red-600"
        />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="iconColor"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Icon color</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="iconColor"
          name="iconColor"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Couleur des icônes"
          v-model="this.marketingDetails.iconColor"
        />
        <ErrorMessage name="iconColor" class="mt-2 text-sm text-red-600" />
      </div>
    </div>
  </div>
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";

export default {
  components: {
    ErrorMessage,
    Field,
  },

  data() {
    return {
      marketingDetails: {
        backgroundColor: "",
        backgroundImageUrl: "",
        buttonBackgroundColor: "",
        buttonColor: "",
        logoUrl: "",
        iconBackgroundColor: "",
        iconColor: "",
      },
      marketingDetailDefaultConfiguration: false,
    };
  },

  watch: {
    marketingDetails: {
      deep: true,
      handler(newValue) {
        this.$emit("update:marketingDetails", newValue);
      },
    },
    marketingDetailDefaultConfiguration: function (newValue) {
      this.$emit("update:marketingDetailDefaultConfiguration", newValue);
    },
  },
};
</script>
