<template>
  <div>
    <div class="flex content-center">
      <h3 class="text-xl pb-4 pl-2 font-bold tracking-tight text-gray-900 mr-4">
        Leads
      </h3>
      <Switch
        v-model="enabled"
        :class="[
          enabled ? 'bg-primary' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
        ]"
      >
        <span class="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          :class="[
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
    </div>
    <dl
      v-if="enabled"
      class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4"
    >
      <div
        v-for="(metric, value) in leadMetricsData"
        :key="metric"
        class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt class="text-sm font-medium leading-6 text-gray-500">
          {{ value }}
        </dt>
        <dd
          class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900"
        >
          {{ metric }}
        </dd>
      </div>
    </dl>
  </div>
</template>
<script>
import BackofficeService from "@/api/services/backoffice";
import { Switch } from "@headlessui/vue";
import { mapMutations } from "vuex";

export default {
  components: { Switch },
  data() {
    return {
      leadMetricsData: [],
      enabled: false,
    };
  },

  watch: {
    enabled: function (newValue) {
      if (newValue === true) {
        this.getLeadsData();
      } else {
        this.leadMetricsData = [];
      }
    },
  },

  methods: {
    ...mapMutations("backoffice", ["setIsLoading"]),

    getLeadsData: async function () {
      this.setIsLoading(true);
      const response = await BackofficeService.getLeadMetricsData();
      this.leadMetricsData = response.data.leadMetricsData;
      this.setIsLoading(false);
    },
  },
};
</script>
