<template>
  <tbody class="divide-y divide-gray-200">
    <tr
      v-for="item in averageAndTotalStats"
      :key="item.name"
      :class="`hover:bg-gray-50 bg-white grid ${gridCols} ${cursorPointer}`"
      @click="onRowClick(item.id)"
    >
      <td
        class="break-words overflow-hidden flex items-center col-span-1 py-2 pl-2 text-sm font-medium text-gray-900 sticky left-0 bg-white hover:bg-gray-50"
        style="border: 1px solid #ccc"
      >
        {{ item.name }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc"
      >
        {{ item.salesCount }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc"
        v-if="isObjectValid(item.salesCountTarget)"
      >
        {{ item.salesCountTarget }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc"
        v-if="isObjectValid(item.salesCountResultTarget)"
      >
        <div
          class="inline-block px-2 py-1 rounded-xl"
          :class="{
            'bg-green-100 text-green-500 border border-green-300':
              item.salesCountResultTarget > 80,
            'bg-yellow-100 text-yellow-500 border border-yellow-300':
              item.salesCountResultTarget >= 50,
            'bg-red-100 text-red-500 border border-red-300':
              item.salesCountResultTarget < 50,
          }"
        >
          {{ item.salesCountResultTarget }} %
        </div>
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc; border-left: 4px solid #ccc"
      >
        {{ item.salesCountProjection }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc"
        v-if="isObjectValid(item.salesCountProjectionTarget)"
      >
        <div
          class="inline-block px-2 py-1 rounded-xl"
          :class="{
            'bg-green-100 text-green-500 border border-dashed border-green-300 ':
              item.salesCountProjectionTarget > 80,
            'bg-yellow-100 text-yellow-500 border border-dashed border-yellow-300':
              item.salesCountProjectionTarget >= 50,
            'bg-red-100 text-red-500 border border-dashed border-red-300':
              item.salesCountProjectionTarget < 50,
          }"
        >
          {{ item.salesCountProjectionTarget }} %
        </div>
      </td>
      <td
        class="col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc; border-right: 4px solid #ccc"
      >
        <div
          class="inline-block px-2 py-1 rounded-xl"
          :class="{
            'bg-green-100 text-green-500 border border-dashed border-green-300':
              this.formatVariation(item.salesCountProjectionVariation) > 0 ||
              this.formatVariation(item.salesCountProjectionVariation) ===
                '+ ∞',
            'bg-red-100 text-red-500 border border-dashed border-red-300':
              this.formatVariation(item.salesCountProjectionVariation) < 0,
            'bg-gray-100 text-gray-500 border border-dashed border-gray-300':
              this.formatVariation(item.salesCountProjectionVariation) === 0,
          }"
        >
          {{ this.formatVariation(item.salesCountProjectionVariation) }}
          %
        </div>
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc"
      >
        {{ item.leadCount }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc"
      >
        {{ item.leadConversionRate }} %
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc"
      >
        {{ item.cancellationRate }} %
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-sm text-gray-500"
        style="border: 1px solid #ccc"
      >
        {{ item.monthlySalesRate }} %
      </td>
    </tr>
  </tbody>
</template>

<script>
import { formatVariation } from "@/utils/variation_formatter";
import { isObjectValid } from "@/utils/validation";

export default {
  name: "StatsTable",
  props: {
    averageAndTotalStats: {
      type: Array,
      required: true,
    },
    gridCols: {
      type: String,
      default: "grid-cols-8",
    },
    cursorPointer: {
      type: String,
      default: "",
    },
    onRowClick: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    formatVariation,
    isObjectValid,
  },
};
</script>
