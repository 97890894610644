import { isStringValid } from "@/utils/validation";

export function formatPrice(locale, price, currencyCode) {
  const defaultCurrencyCode = "EUR";
  let options = {
    style: "currency",
    currency: isStringValid(currencyCode) ? currencyCode : defaultCurrencyCode,
  };
  return new Intl.NumberFormat(locale, options).format(price);
}
