<template>
  <div>
    <ClaimListTitle />
    <SearchBar
      v-bind:title="$t('Claim filters')"
      v-bind:placeholder="$t('SearchClaims')"
      v-bind:current-page="this.currentPage"
      v-bind:available-filters="this.availableFilters"
      v-bind:selected-filters="this.selectedFilters"
      v-bind:initial-keywords="this.keywords"
      @updateFilterSearch="this.updateFilterSearch"
      @updateKeywordSearch="this.updateKeywordSearch"
    />
    <PaginatedClaimList
      class="mt-10"
      v-bind:total-number-of-claims="this.totalNumberOfClaims"
      v-bind:current-page="this.currentPage"
      v-bind:claims="this.claims"
      @redirectToClaimDetailsPage="this.redirectToClaimDetailsPage"
      @changePage="this.handleChangePage"
    />
  </div>
</template>

<script>
import PaginatedClaimList from "@/components/claim/list/PaginatedClaimList.vue";
import {
  getDefaultErrorMessageContent,
  getDefaultErrorMessageTitle,
} from "@/utils/error_messages";
import { mapActions, mapState } from "vuex";
import SearchBar from "@/components/menu/SearchBar.vue";
import ClaimListTitle from "@/components/claim/list/ClaimListTitle.vue";

export default {
  components: {
    ClaimListTitle,
    SearchBar,
    PaginatedClaimList,
  },

  computed: {
    ...mapState("claim_list", [
      "claims",
      "totalNumberOfClaims",
      "availableFilters",
      "selectedFilters",
      "keywords",
    ]),
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  methods: {
    ...mapActions("claim_list", ["getClaims", "updateFilter", "updateSearch"]),

    handleChangePage: function (page) {
      this.updatePage(page);
    },

    retrieveClaims: async function () {
      try {
        await this.getClaims({ page: this.currentPage, });
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: getDefaultErrorMessageTitle(this.$i18n.locale),
              text: error.response.data.error,
            });
            break;
          default:
            this.notify(getDefaultErrorMessageContent(this.$i18n.locale));
        }
      }
    },

    redirectToClaimDetailsPage: function (claimId) {
      this.$router.push(`/claims/${claimId}`);
    },

    updateFilterSearch: function (filterName, filterOptions) {
      this.updatePage(1, false);
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: this.currentPage,
      });
    },

    updateKeywordSearch: function (keyword) {
      this.updatePage(1, false);
      this.updateSearch({
        searchString: keyword,
        page: this.currentPage,
      });
    },

    updatePage: function (newPage, refreshClaims = true) {
      this.currentPage = newPage;
      history.pushState({}, "", `?page=${newPage}`);
      if (refreshClaims) {
        this.retrieveClaims();
      }
    },
  },

  created() {
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.retrieveClaims();
  },
};
</script>
