<template>
  <div class="border-b border-gray-200">
    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
      <a
        v-for="tab in tabs"
        :key="tab.name"
        @click.prevent="selectTab(tab.id)"
        :class="[
          tab.active
            ? 'border-primary text-primary-dark'
            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
          'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
        ]"
      >
        {{ tab.name }}
      </a>
    </nav>
  </div>

  <div class="py-5">
    <div v-if="currentTab.id === 'Stores'">
      <Store />
    </div>
    <div v-if="currentTab.id === 'Organizations'">
      <Organizations />
    </div>
  </div>
</template>

<script>
import Organizations from "@/views/organizations/Organizations.vue";
import Store from "@/views/stores/Store.vue";

export default {
  components: {
    Organizations,
    Store,
  },

  data() {
    return {
      tabs: [
        { name: "Marchands", id: "Organizations", active: true },
        { name: "Points de vente", id: "Stores", active: false },
      ],
    };
  },

  methods: {
    selectTab: function (selectedTab) {
      this.tabs.forEach((tab) => {
        tab.active = tab.id === selectedTab;
      });
    },
  },

  computed: {
    currentTab: function () {
      return this.tabs.find((tab) => tab.active);
    },
  },
};
</script>
