<template>
  <Modal v-bind:open="open" @close="close">
    <div>
      <h1 class="text-center font-extrabold text-xl">
        Sélectionner le type d'annulation
      </h1>
      <div class="flex justify-center">
        <RadioGroup v-model="selectedCancellationType" class="mt-2">
          <RadioGroupLabel class="sr-only"></RadioGroupLabel>
          <div class="grid grid-cols-1 gap-3 sm:grid-cols-3">
            <RadioGroupOption
              as="template"
              v-for="option in cancellationTypes"
              :key="option.name"
              :value="option.name"
              v-slot="{ active, checked }"
            >
              <div
                :class="[
                  checked
                    ? 'border-primary ring-2 ring-primary'
                    : 'border-gray-300',
                  active ? 'border-primary ring-2 ring-primary' : '',
                  'relative overflow-hidden flex border bg-white p-2 shadow-sm focus:outline-none flex-grow rounded-md cursor-pointer',
                ]"
              >
                <RadioGroupLabel as="span" class="text-sm text-center">{{
                  $t(option.name)
                }}</RadioGroupLabel>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </div>

      <termination-form
        :termination="selectedCancellationType"
        @updateSelectedReason="updateSelectedReason"
        @updateFeedbackMessage="updateFeedbackMessage"
      />

      <div class="mt-4 flex justify-center">
        <GenericButton @onClick="handlePlanSaleCancel">
          Annuler le contrat
        </GenericButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import TerminationForm from "@/components/plan_sale/TerminationForm.vue";
import { isStringValid } from "@/utils/validation";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
    GenericButton,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    TerminationForm,
  },

  data() {
    return {
      cancellationTypes: [
        { name: "retraction" },
        { name: "termination" },
        { name: "correction" },
      ],
      selectedCancellationType: "retraction",
      selectedReason: "",
      feedbackMessage: "",
    };
  },

  props: ["open"],

  methods: {
    ...mapActions("notifications", ["notify"]),

    handlePlanSaleCancel: function () {
      if (this.validFormFields()) {
        this.$emit(
          "cancelPlanSale",
          this.selectedCancellationType,
          this.selectedReason,
          this.feedbackMessage
        );
      }
    },

    close: function () {
      this.$emit("close");
    },

    updateSelectedReason(value) {
      this.selectedReason = value;
    },

    updateFeedbackMessage(value) {
      this.feedbackMessage = value;
    },

    validFormFields() {
      if (this.validCancellationReason()) {
        return true;
      } else {
        this.notify({
          category: "simple",
          type: "error",
          title: "Cancellation reason missing",
          text: "Please provide a cancellation reason",
        });
        return false;
      }
    },

    validCancellationReason() {
      return (
        isStringValid(this.selectedReason) ||
        this.selectedCancellationType === "correction"
      );
    },
  },
};
</script>
