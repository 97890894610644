<template>
  <div v-if="this.planSale !== undefined && this.planSale !== null">
    <div>
      <div>
        <div class="flex sm:flex-row flex-col justify-between">
          <h1 class="text-3xl pb-4 font-extrabold tracking-tight text-gray-900">
            {{ "Plan Sale Details" }}
          </h1>
          <div class="flex gap-4">
            <div
              v-if="planSale.renewalAvailable && planSale.expiredOrExpiringSoon"
            >
              <GenericButton @click="openRenewalModal = true">
                Renouveler
              </GenericButton>
              <RenewalModal
                :open="openRenewalModal"
                :plan-sale="planSale"
                @close="openRenewalModal = false"
                @renewPlanSale="renewPlanSale"
                @getRenewPlanSaleLink="getRenewPlanSaleLink"
              />
            </div>
            <div v-if="this.planSale.status !== 'cancelled'">
              <GenericButton @click="this.openModal = true">
                Résilier
              </GenericButton>
              <CancellationModal
                v-bind:open="this.openModal"
                @close="this.openModal = false"
                @cancelPlanSale="this.cancelPlanSale"
              />
            </div>
            <div v-if="this.planSale.status === 'active'">
              <GenericButton @click="this.openUpdateModal = true">
                Modifier
              </GenericButton>
              <UpdateModal
                :open="openUpdateModal"
                :planSale="planSale"
                @closeUpdateModal="openUpdateModal = false"
                @updatePlanSale="updatePlanSale"
              />
            </div>
          </div>
        </div>
        <Cancellation
          v-bind:cancellation="this.cancellation"
          v-if="this.cancellation"
        />
        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          Information sur la protection
          <span class="font-bold">{{ planSale.id }}</span>
        </p>
      </div>

      <div class="mt-4">
        <div class="mt-2 border-t border-gray-100">
          <dl
            class="bg-white px-4 sm:px-6 py-5 grid grid-cols-2 gap-x-4 gap-y-6"
          >
            <h2 class="col-span-2 text-xl font-bold">Client</h2>
            <div>
              <dt class="text-sm font-bold text-gray-500">Nom Prénom</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.customer.firstName }}
                {{ planSale.customer.lastName }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Email</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.customer.email }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Adresse</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <p v-if="planSale.customer.address">
                  {{ planSale.customer.address }}
                </p>
                <p class="font-bold" v-else>—</p>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                Numéro de téléphone
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.customer.phone }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Date de naissance</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.customer.birthDate }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Lieu de naissance</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ customerBirthAddress }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                Création du compte
              </dt>
              <LeadInfoBox
                class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-primary-dark"
                v-bind:textToCopy="planSale.signUpUrl"
              >
                Lien
              </LeadInfoBox>
            </div>
            <div v-if="planSale.orderReferenceId">
              <dt class="text-sm font-bold text-gray-500">
                Numéro de commande marchand
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.orderReferenceId }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="mt-6">
        <div class="mt-2 border-t border-gray-100">
          <dl
            class="bg-white px-4 sm:px-6 py-5 grid grid-cols-2 gap-x-4 gap-y-6"
          >
            <h2 class="col-span-2 text-xl font-bold">Assurance</h2>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                Prix de l'assurance
              </dt>
              <dd class="mt-1 text-sm text-gray-900">{{ planSale.price }} €</dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Paiement</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.monthlyBilling ? "Mensuel" : "Annuel" }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Date d'achat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.purchaseDate }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                Date de début de contrat
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.startDate }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                Bulletin D'adhésion
              </dt>
              <LeadInfoBox
                class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 cursor-pointer underline-offset-4 hover:text-primary-dark"
                v-bind:textToCopy="planSale.membershipAgreement"
              >
                Lien
              </LeadInfoBox>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Encaissé par</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.chargedBy }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Boutique</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ planSale.salesChannel }}
              </dd>
            </div>
            <div v-if="planSale.monthlyBilling">
              <dt class="text-sm font-bold text-gray-500">Abonnement Stripe</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <a
                  :href="`https://dashboard.stripe.com/subscriptions/${planSale.subscriptionReferenceId}`"
                  target="_blank"
                  class="cursor-pointer underline underline-offset-1 text-primary hover:text-primary-dark"
                >
                  {{ planSale.subscriptionReferenceId }}
                </a>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Vendeur</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <p v-if="planSale.seller?.name">{{ planSale.seller?.name }}</p>
                <p class="font-bold" v-else>—</p>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="mt-6">
        <InsuredProductsInformations v-bind:plan="this.planSale" />
      </div>
    </div>
    <PlanSaleBillingDetails
      :planSaleId="this.planSale.id"
      :planSaleBillings="this.planSale.planSaleBillings"
    />
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import PlanSaleBillingDetails from "@/views/plan_sales/details/PlanSaleBillingDetails.vue";
import LeadInfoBox from "@/views/leads/LeadInfoBox.vue";
import Cancellation from "@/views/plan_sales/details/Cancellation.vue";
import CancellationModal from "@/components/plan_sale/CancellationModal.vue";
import UpdateModal from "@/components/plan_sale/UpdateModal.vue";
import InsuredProductsInformations from "@/views/plan_sales/details/InsuredProductsInformations.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import {
  getDefaultErrorMessageContent,
  getDefaultErrorMessageTitle,
} from "@/utils/error_messages";
import { mapActions } from "vuex";
import RenewalModal from "@/components/plan_sale/RenewalModal.vue";

export default {
  components: {
    RenewalModal,
    PlanSaleBillingDetails,
    LeadInfoBox,
    Cancellation,
    GenericButton,
    CancellationModal,
    UpdateModal,
    InsuredProductsInformations,
  },
  data: () => {
    return {
      planSale: null,
      openModal: false,
      openUpdateModal: false,
      openRenewalModal: false,
    };
  },

  beforeMount() {
    this.getPlanSale(this.$route.params.id);
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    getPlanSale: async function (id) {
      const response = await BackofficeService.getPlanSale(id);
      this.planSale = response.data;
      this.cancellation = response.data.cancellation;
    },
    copyToClipboard(textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {});
    },
    cancelPlanSale: async function (
      cancellationType,
      cancellationReason,
      feedbackMessage
    ) {
      try {
        await BackofficeService.cancelPlanSale(
          this.planSale.id,
          cancellationType,
          cancellationReason,
          feedbackMessage
        );
        this.openModal = false;
        this.notify({
          category: "simple",
          type: "success",
          title: "This plan sale has been cancelled.",
        });
        this.getPlanSale(this.planSale.id);
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: getDefaultErrorMessageTitle(this.$i18n.locale),
              text: error.response.data.error,
            });
            break;
          default:
            this.notify(getDefaultErrorMessageContent(this.$i18n.locale));
        }
      }
    },
    updatePlanSale: async function (updatePlanSaleInformations) {
      try {
        await BackofficeService.updatePlanSale(
          this.planSale.id,
          updatePlanSaleInformations
        );
        this.openUpdateModal = false;
        this.notify({
          category: "simple",
          type: "success",
          title: "This plan sale has been updated.",
        });
        this.getPlanSale(this.planSale.id);
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: getDefaultErrorMessageTitle(this.$i18n.locale),
              text: error.response.data.error,
            });
            break;
          default:
            this.notify(getDefaultErrorMessageContent(this.$i18n.locale));
        }
      }
    },

    getRenewPlanSaleLink: async function () {
      const response = await BackofficeService.getRenewPlanSaleLink(
        this.planSale.id
      );
      this.copyToClipboard(response.data.plan_sale_renewal_link);
      await this.notify({
        category: "simple",
        type: "success",
        title: "Link copied to clipboard!",
      });
      this.openRenewalModal = false;
    },

    renewPlanSale: async function () {
      try {
        await BackofficeService.renewPlanSale(this.planSale.id);
        await this.notify({
          category: "simple",
          type: "success",
          title: "Email successfully sent!",
        });
        this.openRenewalModal = false;
      } catch (error) {
        await this.notify({
          category: "simple",
          type: "error",
          title: "Email couldn't be sent, please contact the technical team",
        });
      }
    },
  },

  computed: {
    customerBirthAddress() {
      const birthCity = this.planSale.customer.birthCity;
      const birthCountry = this.planSale.customer.birthCountry;

      let birthAddr = [];
      if (birthCity) birthAddr.push(birthCity);
      if (birthCountry) birthAddr.push(birthCountry);
      return birthAddr.join(", ");
    },
  },
};
</script>
