<template>
  <Modal v-bind:open="this.openModal" @close="this.closeModal">
    <form>
      <div class="space-y-12">
        <div class="pb-12">
          <h2 class="text-xl font-semibold leading-7 text-gray-900">
            Organization
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Sélectionnez l'organisation
          </p>

          <SelectOrganization
            @update:selectedOrganizationId="
              organizationId = $event
            "
          />

          <h2 class="mt-10 text-xl font-semibold leading-7 text-gray-900">
            Store Manager
          </h2>
          <StoreManager @update:storeManager="storeManager = $event" />

          <h2 class="mt-10 text-xl font-semibold leading-7 text-gray-900">
            General settings
          </h2>
          <GeneralSettings @update:generalSettings="generalSettings = $event" />

          <h2 class="mt-10 text-xl font-semibold leading-7 text-gray-900">
            Contracts
          </h2>
          <Contracts
            v-bind:contractCategories="this.contractCategories"
            v-bind:marketingPages="this.marketingPages"
            v-bind:productCategories="this.productCategories"
            @update:contracts="contracts = $event"
          />

          <h2 class="mt-10 text-xl font-semibold leading-7 text-gray-900">
            Marketing Details
          </h2>
          <p class="my-1 text-sm leading-6 text-gray-600">
            Cette section concerne la page de souscription publique
          </p>
          <MarketingDetails
            @update:marketingDetails="marketingDetails = $event"
            @update:marketingDetailDefaultConfiguration="
              marketingDetailDefaultConfiguration = $event
            "
          />
        </div>
      </div>

      <div class="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          class="text-sm font-semibold leading-6 text-gray-900"
          @click="this.closeModal"
        >
          Annuler
        </button>
        <div
          type="submit"
          class="cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-button-font-primary bg-primary uppercase shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          @click="createStore"
        >
          Créer
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import Modal from "@/components/modal/Modal.vue";
import SelectOrganization from "@/views/stores/create/components/SelectOrganization.vue";
import StoreManager from "@/views/stores/create/components/StoreManager.vue";
import GeneralSettings from "@/views/stores/create/components/GeneralSettings.vue";
import Contracts from "@/views/stores/create/components/Contracts.vue";
import MarketingDetails from "@/views/stores/create/components/MarketingDetails.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
    SelectOrganization,
    GeneralSettings,
    StoreManager,
    Contracts,
    MarketingDetails,
  },
  props: ["openModal"],

  data() {
    return {
      contractCategories: [],
      productCategories: [],
      marketingPages: [],
      organizationId: "",
      storeManager: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
      generalSettings: {
        salesChannel: "",
        shopName: "",
        logoUrl: "",
      },
      claimSettings: {
        handleClaims: "",
        retrievalOption: "",
      },
      contracts: [],
      marketingDetails: {
        backgroundColor: "",
        backgroundImageUrl: "",
        buttonBackgroundColor: "",
        buttonColor: "",
        logoUrl: "",
        iconBackgroundColor: "",
        iconColor: "",
      },
      marketingDetailDefaultConfiguration: "",
    };
  },

  created() {
    this.getContractCategories();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    closeModal: function () {
      this.organizationId = "";

      this.storeManager = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      };

      this.generalSettings = {
        salesChannel: "",
        shopName: "",
        logoUrl: "",
      };

      this.claimSettings = {
        handleClaims: "",
        retrievalOption: "",
      };

      this.contracts = [];

      this.marketingDetails = {
        backgroundColor: "",
        backgroundImageUrl: "",
        buttonBackgroundColor: "",
        buttonColor: "",
        logoUrl: "",
        iconBackgroundColor: "",
        iconColor: "",
        isDefaultConfiguration: "",
      };

      this.marketingDetailDefaultConfiguration = "";

      this.$emit("close");
    },
    getContractCategories: async function () {
      const response = await BackofficeService.getContractCategories();
      this.contractCategories = response.data.contractCategories;
      this.productCategories = response.data.productCategories;
      this.marketingPages = response.data.marketingPages;
    },

    createStore: async function () {
      let storeData = {
        organizationId: this.organizationId,
        store: {
          salesChannel: this.generalSettings.salesChannel,
          source: this.generalSettings.source,
          storeLogoUrl: this.generalSettings.logoUrl,
          shop: this.generalSettings.shop,
          shopName: this.generalSettings.shopName,
          businessVerticals: this.contracts,
          marketingDetail: this.marketingDetails,
          claimSetting: this.claimSettings,
          marketingDetailDefaultConfiguration:
            this.marketingDetailDefaultConfiguration,
        },
        storeManager: this.storeManager,
      };

      try {
        await BackofficeService.createStore(storeData);
        this.notify({
          category: "simple",
          type: "success",
          title: "Store is created.",
          text: "Try to connect to the store manager account.",
        });
        this.closeModal();
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: error?.response?.data?.error,
          text:
            error?.response?.data?.message +
            " :" +
            String(error?.response?.data?.error_details),
        });
      }
    },
  },
};
</script>
