import AuthService from "../../api/services/auth";
import UserService from "../../api/services/user";

export default {
  namespaced: true,
  state: {
    currentUser: {},
    signedIn: false,
    csrf: null,
  },

  getters: {
    isEstalyManager: () => true, // TODO : to change (state) => state.currentUser.role === "estalymanager",
  },

  mutations: {
    setCurrentUser(state, { currentUser, csrf }) {
      state.currentUser = currentUser;
      state.signedIn = true;
      state.csrf = csrf;
    },

    unsetCurrentUser(state) {
      state.currentUser = {};
      state.signedIn = false;
      state.csrf = null;
    },

    refresh(state, csrf) {
      state.csrf = csrf;
      state.signedIn = true;
    },
  },

  actions: {
    async signin({ commit }, user) {
      try {
        const response = await AuthService.signin(user);
        if (response.data.csrf) {
          const userResponse = await UserService.getUser();
          const csrf = response.data.csrf;
          const currentUser = userResponse.data;
          commit("setCurrentUser", { currentUser, csrf });
        } else {
          commit("unsetCurrentUser");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async signout({ commit }) {
      try {
        await AuthService.signout();
        commit("unsetCurrentUser");
        localStorage.clear();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
