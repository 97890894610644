<template>
  <div>
    <label
      :for="id"
      class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5"
    >
      {{ $t(label) }}
    </label>
    <div class="mt-2">
      <Field
        :id="id"
        :name="name"
        :type="type"
        :class="fieldClass"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import { Field } from "vee-validate";

export default {
  components: {
    Field,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    fieldClass: {
      type: String,
      default: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
    },
  },
  emits: ["update:modelValue"],
};
</script>
