<template>
  <div>
    <div>
      <label
        for="organization"
        class="block text-sm font-medium leading-6 text-gray-900"
        >Organization</label
      >
      <select
        v-model="organizationSelectorSelectedOrganizationId"
        id="organization"
        name="organization"
        class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
      >
        <option
          v-for="organization in this.organizations"
          :key="organization.id"
          :value="organization.id"
        >
          {{ organization.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("backoffice", ["organizations"]),
  },

  components: {},

  data() {
    return {
      organizationSelectorSelectedOrganizationId: "",
    };
  },

  watch: {
    organizationSelectorSelectedOrganizationId: function (newValue) {
      this.$emit("organizationSelectorSelectedOrganizationId", newValue);
    },
  },
};
</script>
