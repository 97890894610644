<template>
  <div class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("Plan information") }}
      </h3>
    </div>
    <div class="border-t border-gray-200">
      <dl class="bg-white px-4 sm:px-6 py-5 grid grid-cols-2 gap-x-4 gap-y-6">
        <div>
          <dt class="text-sm font-bold text-gray-500">Plan Sale</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <router-link
              class="hover:text-primary-dark hover:underline"
              :to="`/plan-sales/${claim.planSale.customId}`"
              >Voir les détails de la police</router-link
            >
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">ID</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ claim.planSale.customId }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Date d'adhésion</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ claim.planSale.date }}</dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Date d'expiration</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ this.planSaleExpiration }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Prix</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ this.getProductPrice }}</dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Prix du produit</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ claim.planSale.productPrice }}€
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Bulletin d'adhésion</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <a
              class="inline-flex p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary"
              :href="claim.planSale.subscriptionForm"
              target="_blank"
            >
              <EyeIcon class="h-5 w-5 cursor-pointer" aria-hidden="true" />
            </a>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { EyeIcon } from "@heroicons/vue/solid";

export default {
  components: {
    EyeIcon,
  },

  props: ["claim"],
  computed: {
    getProductPrice() {
      return this.claim.planSale.monthly
        ? `${this.claim.planSale.price}€/mois`
        : `${this.claim.planSale.price}€`;
    },

    planSaleExpiration() {
      return this.claim.planSale.monthly
        ? "Tacite reconduction"
        : this.claim.planSale.expiresOn;
    },
  },

  methods: {},
};
</script>
