import { securedAxiosInstance } from "../axios";

class AnalyticsService {
  getRetailData(organizationId, storeId, startDate, endDate, sortParams, salesFieldManagerId) {
    return securedAxiosInstance.get(
      `/backoffice/analytics/retail` +
        `?organization_id=${organizationId}&store_id=${storeId}&sortColumn=${sortParams["column"]}&sortOrder=${sortParams["order"]}&sales_field_manager_id=${salesFieldManagerId}`, {
        params: {
          start: startDate,
          end: endDate,
        }
      }
    );
  }

  getBusinessData(organizationId, storeId, startDate, endDate) {
    return securedAxiosInstance.get(
      `/backoffice/analytics` +
        `?organization_ids=${organizationId}&store_ids=${storeId}&start=${startDate}&end=${endDate}`
    );
  }
}

export default new AnalyticsService();
