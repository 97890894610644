<template>
  <div class="flex items-center justify-between">
    <h1 class="text-3xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
      {{ $t("Plan sales") }}
    </h1>
    <button
      class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary uppercase transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none"
      @click="redirectToSellers"
    >
      Vendeurs
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    redirectToSellers: function () {
      this.$router.push(`/plan-sales/sellers`);
    },
  },
};
</script>
