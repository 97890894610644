<template>
  <div
    class="mt-4 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0"
  >
    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="source"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >source</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="source"
          name="source"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="offline, shopify, woo_commerce, prestashop..."
          v-model="this.generalSettings.source"
        />
        <ErrorMessage name="address" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="salesChannel"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Sales Channel</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="salesChannel"
          name="salesChannel"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Nom du point de vente"
          v-model="this.generalSettings.salesChannel"
        />
        <ErrorMessage name="address" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="shopName"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Shop name</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="shopName"
          name="shopName"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Shop name est utilisé pour le lien public de souscription"
          v-model="this.generalSettings.shopName"
        />
        <ErrorMessage name="shopName" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="shop"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Shop</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="shop"
          name="shop"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="lien du site web pour les stores online"
          v-model="this.generalSettings.shop"
        />
        <ErrorMessage name="shop" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
      <label
        for="logoUrl"
        class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >Logo URL</label
      >
      <div class="mt-2 sm:col-span-2 sm:mt-0">
        <Field
          id="logoUrl"
          name="logoUrl"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="Url du logo du store"
          v-model="this.generalSettings.logoUrl"
        />
        <ErrorMessage name="logoUrl" class="mt-2 text-sm text-red-600" />
      </div>
    </div>
  </div>
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";

export default {
  components: {
    ErrorMessage,
    Field,
  },

  data() {
    return {
      generalSettings: {
        salesChannel: "",
        shopName: "",
        logoUrl: "",
        shop: "",
        source: "",
      },
    };
  },

  watch: {
    generalSettings: {
      deep: true,
      handler(newValue) {
        this.$emit("update:generalSettings", newValue);
      },
    },
  },
};
</script>
