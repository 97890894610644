<template>
  <div v-if="this.generalInformations" class="bg-white shadow sm:rounded-lg">
    <div class="grid grid-cols-1 sm:grid-cols-2 px-4 py-5 sm:px-6">
      <div class="flex justify-center items-center">
        <img
          v-bind:src="this.generalInformations.logoUrl"
          v-bind:alt="this.generalInformations.name"
          class="max-w-full h-auto"
        />
      </div>
      <div>
        <h3 class="px-4 text-lg leading-6 font-medium text-gray-900 font-bold">
          {{ this.generalInformations.name }}
        </h3>
        <div>
          <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
            <div>
              <dt class="text-sm font-bold text-gray-500">{{ $t("Id") }}</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <FieldSpan
                  v-bind:style-to-add="'bg-purple-600 text-white'"
                  v-bind:value="this.generalInformations.id"
                />
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                {{ $t("Organization") }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ this.generalInformations.organization }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">Source</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ this.generalInformations.source }}
              </dd>
            </div>
            <div v-if="this.generalInformations.shop">
              <dt class="text-sm font-bold text-gray-500">{{ $t("Link") }}</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <a
                  class="text-blue-400 underline hover:font-bold"
                  target="_blank"
                  v-bind:href="this.generalInformations.shop"
                >
                  Lien
                </a>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                {{ $t("Post Purchase") }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                <FieldSpan
                  v-bind:style-to-add="
                    this.generalInformations.postPurchaseEnabled
                      ? 'bg-green-100'
                      : 'bg-red-100'
                  "
                  v-bind:value="this.generalInformations.postPurchaseEnabled"
                />
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                {{ $t("Monthly sales target") }}
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ this.generalInformations.monthlySalesTarget }}
              </dd>
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                {{ $t("Sales field manager") }}
              </dt>
              <SalesFieldManagerSelector
                :salesFieldManagers="salesFieldManagers"
                :selectedSalesFieldManager="
                  generalInformations.salesFieldManager
                "
                @update:selectedSalesFieldManager="onSalesFieldManagerChange"
              />
            </div>
            <div>
              <dt class="text-sm font-bold text-gray-500">
                Lien public de souscription
              </dt>
              <dd
                class="mt-1 text-sm text-gray-900 cursor-pointer hover:underline hover:text-primary-dark"
                v-on:click="copyToClipboard"
              >
                Copier le lien
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import SalesFieldManagerSelector from "@/components/analytics/retail/utils/SalesFieldManagerSelector.vue";

export default {
  components: {
    FieldSpan,
    SalesFieldManagerSelector,
  },
  props: ["generalInformations", "salesFieldManagers"],
  methods: {
    onSalesFieldManagerChange: function (salesFieldManagerId) {
      this.$emit("update:selectedSalesFieldManager", salesFieldManagerId);
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.generalInformations.publicSubscriptionLink)
        .then(() => {});
    },
  },
};
</script>
