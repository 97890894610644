import { createStore } from "vuex";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import backoffice from "./modules/backoffice";
import VuexPersistence from "vuex-persist";
import navigation from "@/store/modules/navigation";
import claims from "@/store/modules/claims";
import claim_list from "@/store/modules/claim_list";
import plan_sale_list from "@/store/modules/plan_sale_list";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  modules: {
    auth,
    notifications,
    backoffice,
    navigation,
    claims,
    claim_list,
    plan_sale_list,
  },
  plugins: [vuexLocal.plugin],
});
