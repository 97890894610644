<template>
  <Modal
    v-if="this.claimHistoricalData !== null"
    v-bind:open="this.open"
    @close="this.closeModal"
  >
    <div>
      <p class="block text-xl font-medium leading-6 text-gray-900">
        Status details
      </p>
      <span
        class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium"
      >
        {{ this.claimHistoricalData.status }}
      </span>

      <div class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">
          Estaly Manager Notes
        </p>
        <p>{{ this.claimHistoricalData.estalyManagerNotes }}</p>
      </div>

      <div class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">
          Claim Manager Notes
        </p>
        <p>{{ this.claimHistoricalData.claimManagerNotes }}</p>
      </div>

      <div
        v-if="this.claimHistoricalData.clientNotification"
        class="bg-gray-100 p-5 mt-5"
      >
        <p class="block text-sm font-medium leading-6 text-gray-900">
          Client Notification
        </p>
        <div>
          <p>{{ this.claimHistoricalData.clientNotification }}</p>
        </div>
      </div>

      <div
        v-if="this.claimHistoricalData.merchantNotification"
        class="bg-gray-100 p-5 mt-5"
      >
        <p class="block text-sm font-medium leading-6 text-gray-900">
          Merchant Notification
        </p>
        <div>
          <p>{{ this.claimHistoricalData.merchantNotification }}</p>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/modal/Modal.vue";

export default {
  components: { Modal },
  props: ["open", "claim", "claimHistoricalData"],

  methods: {
    closeModal: function () {
      this.$emit("close");
    },
  },
};
</script>
