<template>
  <div class="my-10" v-if="cancellation.date">
    <div class="mt-4">
      <h2 class="text-xl font-bold">Résiliation</h2>
      <div class="mt-2 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">Date</dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ cancellation.date }}
            </dd>
          </div>
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">
              Date de fin d'encaissement
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ cancellation.billingEndDate }}
            </dd>
          </div>
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">Type</dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{
                cancellation.cancellationType
                  ? $t(cancellation.cancellationType)
                  : ""
              }}
            </dd>
          </div>
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">
              Résilié par
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{
                cancellation.initiatedBy
                  ? $t("Cancellation initiated by." + cancellation.initiatedBy)
                  : ""
              }}
            </dd>
          </div>
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">Raison</dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{
                cancellation.reason
                  ? $t("Plan cancellation reasons." + cancellation.reason)
                  : ""
              }}
            </dd>
          </div>
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">
              Expérience
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ cancellation.feedbackExperience }}
            </dd>
          </div>
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">Message</dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ cancellation.feedbackMessage }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cancellation"],

  components: {},
};
</script>
