<template>
  <div>
    <ProductBulkActionButtons
      v-bind:selected-products="this.selectedProducts"
      @upload="this.uploadProductsToPlatform"
      @unpublish="this.removeProductsFromPlatform"
      @match="this.matchProducts"
      @approve="this.approveProducts"
      @unapprove="this.unapproveProducts"
      @unmatch="this.unmatchProducts"
      @approve-match-publish="this.approveMatchPublishProducts"
      @approve-match="this.approveMatchProducts"
    />
    <div class="flex content-center">
      <OrganizationSelector
        class="w-1/4"
        @updatedSelectedOrganizationId="updatedSelectedOrganizationId"
      />
      <StoreSelector class="ml-4 w-1/4" />
    </div>
    <ProductListMenuFilters
      v-bind:desired-filters="this.desiredFilters"
      v-bind:current-page="this.currentPage"
      @updateKeywordSearch="this.updateKeywordSearch"
      @updateFilterSearch="this.updateFilterSearch"
    />
  </div>
</template>

<script>
import ProductListMenuFilters from "@/views/products/list/menu/filters/ProductListMenuFilters.vue";
import ProductBulkActionButtons from "@/views/products/list/menu/actions/ProductBulkActionButtons.vue";
import OrganizationSelector from "@/views/products/list/menu/selectors/OrganizationSelector.vue";
import StoreSelector from "@/views/products/list/menu/selectors/StoreSelector.vue";
import { mapActions } from "vuex";

const defaultSelectedOrganization = {
  id: null,
  name: "Choose an organization",
};
const defaultSelectedStore = { id: null, name: "Choose a store" };

export default {
  components: {
    OrganizationSelector,
    StoreSelector,
    ProductListMenuFilters,
    ProductBulkActionButtons,
  },
  computed: {
    desiredFilters: function () {
      return [
        "productTypes",
        "vendors",
        "tags",
        "offerStatuses",
        "approvalStatuses",
        "categories",
      ];
    },
  },
  props: ["selectedProducts", "currentPage"],

  created() {
    this.getOrganizations();
  },

  data: () => {
    return {
      selectedOrganization: defaultSelectedOrganization,
      selectedStore: defaultSelectedStore,
    };
  },

  methods: {
    ...mapActions("backoffice", ["getOrganizations"]),
    ...mapActions("backoffice", ["getProducts"]),

    uploadProductsToPlatform: function () {
      this.$emit("upload", this.selectedProducts);
    },
    removeProductsFromPlatform: function () {
      this.$emit("remove", this.selectedProducts);
    },
    updateKeywordSearch: function (keywords) {
      this.$emit("updateKeywordSearch", keywords);
    },
    updateFilterSearch: function (filterName, filterOptions) {
      this.$emit("updateFilterSearch", filterName, filterOptions);
    },
    matchProducts: function () {
      this.$emit("match", this.selectedProducts);
    },
    unmatchProducts: function () {
      this.$emit("unmatch", this.selectedProducts);
    },
    approveProducts: function () {
      this.$emit("approve", this.selectedProducts);
    },
    unapproveProducts: function () {
      this.$emit("unapprove", this.selectedProducts);
    },
    approveMatchPublishProducts: function () {
      this.$emit("approve-match-publish", this.selectedProducts);
    },
    approveMatchProducts: function () {
      this.$emit("approve-match", this.selectedProducts);
    },
    updatedSelectedOrganizationId: function () {
      this.getProducts({
        page: 1,
        parents: true,
      });
    },
  },
};
</script>
