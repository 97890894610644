<template>
  <div>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          {{ $t(`${this.discountTitle}`) }}
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute overflow-y-auto right-0 z-50 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto"
        >
          <div class="py-1">
            <MenuItem
              v-for="(discount, idx) in this.availableDiscounts"
              v-bind:key="idx"
              v-slot="{ active }"
            >
              <button
                @click="updateDiscount(discount)"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  {{ $t(`${discount.title}`) }}
                </p>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import BackofficeService from "@/api/services/backoffice";
import { isObjectValid } from "@/utils/validation";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },

  props: ["storeId", "discounts", "currentDiscount"],

  computed: {
    ...mapState("backoffice", ["availableFilters"]),

    availableDiscounts() {
      return [{ id: null, title: "Aucune" }].concat(this.discounts);
    },
    discountTitle() {
      return isObjectValid(this.currentDiscount)
        ? this.currentDiscount
        : "Aucune";
    },
  },

  methods: {
    updateDiscount: async function (discount) {
      await BackofficeService.updateStoreDiscount(this.storeId, discount.id);
      this.$emit("discountUpdated");
    },
  },
};
</script>
