<template>
  <Modal v-bind:open="this.openModal" @close="this.closeModal"
    >>
    <div
      class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-primary-light"
    >
      <DocumentReportIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </div>

    <VeeForm
      v-slot="{ handleSubmit }"
      :validation-schema="schema"
      as="div"
      ref="discountCreationForm"
    >
      <form @submit="handleSubmit($event, createDiscount)">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h2 class="text-base font-semibold leading-7 text-gray-900">
              Créer une promotion
            </h2>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Entrez les informations concernant la nouvelle promotion
            </p>

            <div>
              <RadioGroup v-model="selectedDiscountType" class="mt-2">
                <RadioGroupLabel class="sr-only"
                  >Choisissez le type de promotion</RadioGroupLabel
                >
                <div class="grid grid-cols-1 gap-3 sm:grid-cols-2">
                  <RadioGroupOption
                    as="template"
                    v-for="option in discountTypes"
                    :key="option.name"
                    :value="option.name"
                    v-slot="{ active, checked }"
                  >
                    <div
                      :class="[
                        checked
                          ? 'border-primary ring-2 ring-primary'
                          : 'border-gray-300',
                        active ? 'border-primary ring-2 ring-primary' : '',
                        'relative overflow-hidden flex border bg-white p-4 shadow-sm focus:outline-none flex-grow rounded-md cursor-pointer',
                      ]"
                    >
                      <RadioGroupLabel as="span">{{
                        $t(option.name)
                      }}</RadioGroupLabel>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>

            <div
              class="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0"
            >
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
              >
                <label
                  for="discountTitle"
                  class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >Titre</label
                >
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    id="discountTitle"
                    name="discountTitle"
                    type="text"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
                    placeholder="Titre de la promotion"
                    v-model="this.discountTitle"
                  />
                  <ErrorMessage
                    name="discountTitle"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
              >
                <label
                  for="offeredMonths"
                  class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >Mois offerts</label
                >
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    id="offeredMonths"
                    name="offeredMonths"
                    type="number"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
                    placeholder="Nombre de mois offerts"
                    v-model="this.offeredMonths"
                  />
                  <ErrorMessage
                    name="offeredMonths"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>

              <div
                v-if="!isDiscountTypeOffered"
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
              >
                <label
                  for="finalPrice"
                  class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >Prix final</label
                >
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    id="finalPrice"
                    name="finalPrice"
                    type="text"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
                    placeholder="0.99"
                    v-model="this.finalPrice"
                  />
                  <ErrorMessage
                    name="finalPrice"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            class="inline-flex justify-center rounded-md text-button-font-primary bg-primary uppercase px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            Créer
          </button>
        </div>
      </form>
    </VeeForm>
  </Modal>
</template>
<script>
import Modal from "@/components/modal/Modal.vue";
import { DocumentReportIcon } from "@heroicons/vue/outline";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import * as yup from "yup";
import BackofficeService from "@/api/services/backoffice";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";

const schema = yup.object({
  discountTitle: yup.string().required("Please specify the discount title"),
  offeredMonths: yup
    .number()
    .required("Please specify the number of months to offer"),
});

export default {
  components: {
    Modal,
    DocumentReportIcon,
    VeeForm,
    Field,
    ErrorMessage,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },

  data() {
    return {
      schema,
      discountTitle: "",
      offeredMonths: 1,
      finalPrice: "",
      discountTypes: [{ name: "offered" }, { name: "discount" }],
      selectedDiscountType: "offered",
    };
  },

  computed: {
    isDiscountTypeOffered() {
      return this.selectedDiscountType === "offered";
    },
  },

  props: ["openModal"],

  methods: {
    closeModal: function () {
      this.$emit("close");
    },

    createDiscount: function () {
      BackofficeService.createDiscount(
        this.selectedDiscountType,
        this.discountTitle,
        this.offeredMonths,
        this.finalPrice
      );
      this.closeModal();
      this.$emit("discountCreated");
    },
  },
};
</script>
