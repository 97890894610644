<template>
  <div
    v-if="insuranceOffers"
    class="bg-gray-100 shadow overflow-hidden sm:rounded-lg"
  >
    <div
      @click="this.toggleVisibility"
      class="flex justify-between items-center px-4 py-5 sm:px-6 cursor-pointer"
    >
      <h3 class="text-lg leading-6 font-bold text-gray-900">
        Verticales proposées
      </h3>
      <div class="w-10">
        <ChevronDownIcon v-if="!this.isVisible" />
        <ChevronUpIcon v-else />
      </div>
    </div>
    <div v-if="this.isVisible" class="flex flex-col">
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Name") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Vertical") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Merchant commission") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Insurer commission") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Estaly commission") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Store additional fee commission") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Marketing page") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Insurer") }}
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 text-sm">
                <tr
                  v-for="(insuranceOffer, index) in insuranceOffers"
                  :key="index"
                  v-bind:class="'hover:bg-gray-100'"
                >
                  <td class="px-3 py-3.5 font-bold">
                    {{ $t(insuranceOffer.name) }}
                  </td>
                  <td class="px-3 py-3.5">
                    {{ $t(insuranceOffer.vertical) }}
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-bind:style-to-add="'bg-green-200'"
                      v-bind:value="insuranceOffer.commissions.merchant"
                    />
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-bind:style-to-add="'bg-yellow-200'"
                      v-bind:value="insuranceOffer.commissions.insurer"
                    />
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-bind:style-to-add="'bg-purple-200'"
                      v-bind:value="insuranceOffer.commissions.estaly"
                    />
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-if="insuranceOffer.commissions.merchantOnAdditionalFee"
                      v-bind:style-to-add="'bg-green-200'"
                      v-bind:value="
                        insuranceOffer.commissions.merchantOnAdditionalFee
                      "
                    />
                    <p class="font-bold" v-else>—</p>
                  </td>
                  <td class="px-3 py-3.5">
                    <a
                      v-bind:href="insuranceOffer.marketingPage"
                      alt="marketing page"
                      target="_blank"
                      class="text-blue-400 underline"
                      >Lien</a
                    >
                  </td>
                  <td class="px-3 py-3.5">
                    {{ insuranceOffer.insurer }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronUpIcon, ChevronDownIcon, FieldSpan },
  props: ["insuranceOffers"],

  data() {
    return {
      isVisible: false, // Initial visibility state
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible; // Toggle the visibility state
    },
  },
};
</script>
