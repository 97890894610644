<template>
  <Modal v-bind:open="this.open" @close="this.closeModal">
    <h1 class="flex items-center justify-center text-2xl">
      Veuillez télécharger le devis de ce sinistre
    </h1>
    <label
      class="mt-10 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      for="file_input"
      >Charger le devis</label
    >
    <input
      class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
      id="file_input"
      type="file"
      @change="this.handleFileUpload"
    />

    <div class="pb-4">
      <label
        for="price"
        class="block text-sm font-medium leading-6 text-gray-900"
        >Montant des réparations</label
      >
      <div class="relative mt-2 rounded-md shadow-sm">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        ></div>
        <input
          type="number"
          name="price"
          id="price"
          class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder="0.00"
          aria-describedby="price-currency"
          v-model="repairCost"
        />
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
        >
          <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
        </div>
      </div>
    </div>

    <div v-if="this.file && this.repairCost" class="flex justify-center">
      <GenericButton class="mt-5" @onClick="this.uploadFile">
        Ajouter
      </GenericButton>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { mapActions } from "vuex";

export default {
  components: { GenericButton, Modal },

  data: () => {
    return {
      file: null,
      repairCost: null,
    };
  },

  methods: {
    ...mapActions("claims", ["uploadClaimQuotation"]),
    ...mapActions("notifications", ["notify"]),

    handleFileUpload: function (event) {
      const fileToAdd = event.target.files[0];

      if (fileToAdd) {
        this.file = fileToAdd;
      }
    },

    uploadFile: async function () {
      if (this.file && this.repairCost) {
        try {
          await this.uploadClaimQuotation({
            repairCost: this.repairCost,
            quotationFile: this.file,
          });
        } catch (error) {
          await this.notify({
            category: "simple",
            type: "error",
            title: "Error when trying to upload the claim quotation",
            text: error.response.data.error,
          });
        }

        this.closeModal();
      }
    },

    closeModal: function () {
      this.$emit("close");
      this.file = null;
      this.repairCost = null;
    },
  },
};
</script>
