<style>
@media (max-width: 1024px) {
  .responsive-table {
    min-width: 1000px !important;
  }
}
</style>

<template>
  <div>
    <div class="min-h-5">
      <a
        href="#"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        v-on:click="handleStoreClick(null)"
      >
        Précédent
      </a>
    </div>
    <div class="overflow-auto" style="max-height: 75vh">
      <table class="mt-10 min-w-full divide-y divide-gray-300 responsive-table">
        <SellerStatsTable
          :averageAndTotalStats="this.averageAndTotalStats"
          grid-cols="grid-cols-8"
        />
      </table>
      <table
        class="min-w-full divide-y divide-gray-300 responsive-table sticky top-0 bg-white z-30"
      >
        <thead>
          <tr class="grid grid-cols-8">
            <th
              scope="col"
              class="col-span-1 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 border-solid sticky left-0 top-0 bg-white"
            ></th>
            <th
              scope="col"
              class="col-span-1 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            ></th>
            <th
              scope="col"
              colspan="2"
              class="bg-gray-100 col-span-2 px-3 py-6 text-left text-sm-xl font-semibold text-gray-900 text-center"
            >
              Projection {{ currentMonth }}
            </th>
            <th
              scope="col"
              class="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            ></th>
            <th
              scope="col"
              class="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            ></th>
            <th
              scope="col"
              class="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            ></th>
          </tr>
          <tr class="grid grid-cols-8">
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border: 1px solid #ccc; position: sticky; left: 0px'"
              columnKey="name"
              @sort="handleSort"
            >
              Nom
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border: 1px solid #ccc;'"
              columnKey="sales_count"
              @sort="handleSort"
            >
              Contrats vendus
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border: 1px solid #ccc; border-left: 4px solid #ccc'"
              columnKey="sales_count_projection"
              @sort="handleSort"
            >
              Contrats vendus
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border: 1px solid #ccc; border-right: 4px solid #ccc'"
              columnKey="sales_count_projection_variation"
              @sort="handleSort"
            >
              Évolution M-1
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border: 1px solid #ccc;'"
              columnKey="lead_count"
              @sort="handleSort"
            >
              Leads créés
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border: 1px solid #ccc;'"
              columnKey="lead_conversion_rate"
              @sort="handleSort"
            >
              Taux de conversion des leads
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border: 1px solid #ccc;'"
              columnKey="cancellation_rate"
              @sort="handleSort"
            >
              Taux de résiliation
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border: 1px solid #ccc;'"
              columnKey="monthly_sales_rate"
              @sort="handleSort"
            >
              Part de policies mensuelles
            </TableHeader>
          </tr>
        </thead>
      </table>
      <table class="min-w-full divide-y divide-gray-300 responsive-table">
        <StatsTable
          :averageAndTotalStats="this.sellerStats"
          grid-cols="grid-cols-8"
        />
      </table>
    </div>
  </div>
</template>

<script>
import TableHeader from "@/components/analytics/retail/utils/TableHeader.vue";
import StatsTable from "@/components/analytics/retail/utils/StatsTable.vue";
import SellerStatsTable from "@/components/analytics/retail/SellerStatsTable.vue";
import { formatVariation } from "@/utils/variation_formatter";

export default {
  components: {
    TableHeader,
    StatsTable,
    SellerStatsTable,
  },

  props: [
    "sellerStats",
    "currentMonth",
    "numberOfSellers",
    "averageAndTotalStats",
  ],

  data: () => {
    return {
      sortColumn: null,
      sortOrder: null,
    };
  },

  methods: {
    handleStoreClick(storeId) {
      this.$emit("storeIdSelected", storeId);
    },

    handleSort(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }

      this.emitSortEvent();
    },

    emitSortEvent() {
      this.$emit("sortChanged", {
        column: this.sortColumn,
        order: this.sortOrder,
      });
    },

    formatVariation,
  },
};
</script>
