<template>
  <div class="pt-6 md:pt-0 relative overflow-x-auto">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead
        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
      >
        <tr class="hidden md:table-row">
          <th scope="col" class="px-6 py-3">ID</th>
          <th scope="col" class="px-6 py-3">Début</th>
          <th scope="col" class="px-6 py-3">Fin</th>
          <th scope="col" class="px-6 py-3">Objectifs de vente</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="challenge in this.challenges"
          v-bind:key="challenge.id"
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
        >
          <th
            scope="row"
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            {{ challenge.id }}
          </th>
          <th
            scope="row"
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            {{ challenge.startDate }}
            <span class="md:hidden">au {{ challenge.endDate }}</span>
            <div class="flex flex-col">
              <span class="md:hidden font-light">
                <ul>
                  <li
                    v-for="(item, index) in challenge.salesTargets"
                    :key="index"
                  >
                    • Target: {{ item.target }} | Reward: {{ item.reward }}€
                  </li>
                </ul></span
              >
            </div>
          </th>
          <td
            class="px-6 py-4 hidden md:table-cell text-gray-900 font-medium whitespace-nowrap dark:text-white"
          >
            {{ challenge.endDate }}
          </td>
          <td
            class="px-6 py-4 hidden md:table-cell text-gray-700 font-normal whitespace-nowrap dark:text-white"
          >
            <ul>
              <li v-for="(item, index) in challenge.salesTargets" :key="index">
                • Target: {{ item.target }} | Reward: {{ item.reward }}€
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["challenges"],
};
</script>
