<template>
  <h1 class="text-3xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
    Métriques produit
  </h1>
  <LeadMetrics class="mt-5" />
  <PlanSaleMetrics class="mt-5" />
  <OnlineOrdersMetrics class="mt-5" />
</template>

<script>
import LeadMetrics from "@/views/metrics/LeadMetrics.vue";
import PlanSaleMetrics from "@/views/metrics/PlanSaleMetrics.vue";
import OnlineOrdersMetrics from "@/views/metrics/OnlineOrdersMetrics.vue";

export default {
  components: {
    PlanSaleMetrics,
    LeadMetrics,
    OnlineOrdersMetrics,
  },
};
</script>
