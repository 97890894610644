<template>
  <div
    v-if="this.billingConfiguration"
    class="bg-gray-100 shadow overflow-hidden sm:rounded-lg"
  >
    <div
      @click="this.toggleVisibility"
      class="flex justify-between items-center px-4 py-5 sm:px-6 cursor-pointer"
    >
      <h3 class="text-lg leading-6 font-bold text-gray-900">
        Informations sur la facturation
      </h3>
      <div class="w-10">
        <ChevronDownIcon v-if="!this.isVisible" />
        <ChevronUpIcon v-else />
      </div>
    </div>
    <div v-if="this.isVisible" class="border-t border-gray-200 bg-white">
      <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Entity") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p v-if="this.billingConfiguration.entity">
              {{ this.billingConfiguration.entity }}
            </p>
            <p class="font-bold" v-else>—</p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Address") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p v-if="this.billingConfiguration.address">
              {{ this.billingConfiguration.address }}
            </p>
            <p class="font-bold" v-else>—</p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Remuneration model") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ this.billingConfiguration.remunerationModel }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Advance months commission") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p v-if="this.billingConfiguration.advanceMonthsCommission">
              {{ this.billingConfiguration.advanceMonthsCommission }}
            </p>
            <p class="font-bold" v-else>—</p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Billing delay") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ this.billingConfiguration.billingDelay }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Churn rate") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p v-if="this.billingConfiguration.churnRate">
              {{ this.billingConfiguration.churnRate }}
            </p>
            <p class="font-bold" v-else>—</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronUpIcon, ChevronDownIcon },
  props: ["billingConfiguration"],

  data() {
    return {
      isVisible: false, // Initial visibility state
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible; // Toggle the visibility state
    },
  },
};
</script>
