<template>
  <div>
    <div class="flex flex-col sm:flex-row sm:justify-between items-start my-6">
      <div class="flex flex-col w-full sm:w-1/2">
        <div class="flex justify-between">
          <p class="font-bold sm:w-1/4">Sélectionner une période</p>
          <button 
            @click="resetToBeginning" 
            class="text-primary-dark underline text-sm"
          >
            Depuis le début
          </button>
        </div>
        <Datepicker
          class="text-sm w-full rounded-md"
          v-model="date"
          range
          multiCalendars
          v-bind:presetRanges="presetRanges"
          autoApply
          v-bind:enableTimePicker="false"
          v-bind:format="format"
          locale="fr"
          :max-date="new Date()"
          :esc-close="false"
        />
      </div>
      <div
        class="w-full mt-4 sm:mt-1 sm:w-1/4"
        v-if="isObjectValid(salesFieldManagers)"
      >
        <p class="font-bold">Sélectionner un animateur</p>
        <SalesFieldManagerSelector
          :salesFieldManagers="salesFieldManagers"
          @update:selectedSalesFieldManager="onSalesFieldManagerChange"
        />
      </div>
    </div>
    <SellerAnalytics
      v-if="selectedStoreId"
      :numberOfSellers="numberOfSellers"
      :currentMonth="currentMonth"
      :sellerStats="stats"
      :averageAndTotalStats="averageAndTotalStats"
      @storeIdSelected="onStoreIdSelected"
      @sortChanged="onSellersSortChange"
    />
    <StoreAnalytics
      v-else-if="selectedOrganizationId"
      :numberOfStores="numberOfStores"
      :currentMonth="currentMonth"
      :storeStats="stats"
      :averageAndTotalStats="averageAndTotalStats"
      @storeIdSelected="onStoreIdSelected"
      @organizationIdSelected="onOrganizationIdSelected"
      @sortChanged="onStoresSortChange"
    />
    <OrganizationAnalytics
      v-else
      :numberOfOrganizations="numberOfOrganizations"
      :currentMonth="currentMonth"
      :organizationStats="stats"
      :averageAndTotalStats="averageAndTotalStats"
      @organizationIdSelected="onOrganizationIdSelected"
      @sortChanged="onOrganizationsSortChange"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";

import OrganizationAnalytics from "@/components/analytics/retail/OrganizationAnalytics.vue";
import StoreAnalytics from "@/components/analytics/retail/StoreAnalytics.vue";
import SellerAnalytics from "@/components/analytics/retail/SellerAnalytics.vue";
import SalesFieldManagerSelector from "@/components/analytics/retail/utils/SalesFieldManagerSelector.vue";
import { isObjectValid } from "@/utils/validation";
import { mapMutations } from "vuex";

import "@vuepic/vue-datepicker/dist/main.css";
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  startOfWeek,
  subWeeks,
  endOfWeek,
} from "date-fns";
import { mapState, mapActions } from "vuex";
import AnalyticsService from "@/api/services/analytics";

export default {
  components: {
    Datepicker,
    OrganizationAnalytics,
    StoreAnalytics,
    SellerAnalytics,
    SalesFieldManagerSelector,
  },

  mounted() {
    if (this.signedIn) {
      this.getAnalytics();
    } else {
      this.$router.push("/signin");
    }
  },

  data: () => {
    return {
      selectedOrganizationId: null,
      selectedStoreId: null,
      stats: null,
      averageAndTotalStats: null,
      organizationsSortParams: {
        column: null,
        order: null,
      },
      storesSortParams: {
        column: null,
        order: null,
      },
      sellersSortParams: {
        column: null,
        order: null,
      },
      salesFieldManagerId: null,
      salesFieldManagers: [],
      numberOfOrganizations: 0,
      numberOfStores: 0,
      numberOfSellers: 0,
      date: [],
      currentMonth: "",
      presetRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            endOfWeek(new Date(), { weekStartsOn: 1 }),
          ],
        },
        {
          id: 3,
          label: "Mois précédent",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
      ],
      comparedTo: {},
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
  },

  created() {
    this.getOrganizations();
    const endDate = endOfMonth(new Date());
    const startDate = startOfMonth(new Date());
    this.date = [startDate, endDate];
  },

  methods: {
    ...mapActions("backoffice", ["getOrganizations"]),
    ...mapMutations("backoffice", ["setIsLoading"]),

    isObjectValid,

    format: function (date) {
      const startDate = date[0];
      const endDate = date[1];

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate && endDate) {
        if (
          startDate instanceof Date &&
          endDate instanceof Date &&
          startDate.getTime() === startDate.getTime() &&
          endDate.getTime() === endDate.getTime()
        ) {
          if (
            this.compareDate(startDate, startOfMonth(new Date())) &&
            this.compareDate(endDate, endOfMonth(new Date()))
          ) {
            return "Mois en cours";
          }
          if (
            this.compareDate(
              startDate,
              startOfMonth(subMonths(new Date(), 1))
            ) &&
            this.compareDate(endDate, endOfMonth(subMonths(new Date(), 1)))
          ) {
            return "Mois précédent";
          }
          if (
            this.compareDate(
              startDate,
              startOfWeek(new Date(), { weekStartsOn: 1 })
            ) &&
            this.compareDate(
              endDate,
              endOfWeek(new Date(), { weekStartsOn: 1 })
            )
          ) {
            return "Semaine en cours";
          }
          if (
            this.compareDate(
              startDate,
              subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            ) &&
            this.compareDate(
              endDate,
              subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            )
          ) {
            return "Semaine précédente";
          }
        }
      }

      return `Du ${startDay}/${startMonth}/${startYear} au ${endDay}/${endMonth}/${endYear}`;
    },

    stringFormat: function (string) {
      return (
        string.split("_").join(" ").charAt(0).toUpperCase() +
        string.split("_").join(" ").slice(1)
      );
    },

    compareDate(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },

    getAnalytics: async function () {
      try {
        this.setIsLoading(true);
        const start = Array.isArray(this.date) && this.date[0] ? this.date[0] : null;
        const end = Array.isArray(this.date) && this.date[1] ? this.date[1] : null;

        const storeId = this.selectedStoreId ? this.selectedStoreId : "";
        const organizationId = this.selectedOrganizationId
          ? this.selectedOrganizationId
          : "";

        const sortParams = this.retrieveSortParams();
        const salesFieldManagerId = this.salesFieldManagerId
          ? this.salesFieldManagerId
          : "";

        const response = await AnalyticsService.getRetailData(
          organizationId,
          storeId,
          start,
          end,
          sortParams,
          salesFieldManagerId
        );

        const data = response.data;
        this.stats = data.stats;
        this.numberOfOrganizations = data.totalOrganizations;
        this.numberOfStores = data.totalStores;
        this.numberOfSellers = data.totalSellers;
        this.averageAndTotalStats = [data.average, data.total];
        this.salesFieldManagers = data.salesFieldManagers;
        this.setIsLoading(false);
      } catch (error) {
        this.setIsLoading(false);
        console.log(error);
      }
    },

    onOrganizationIdSelected(organizationId) {
      this.numberOfOrganizations = 0;
      this.selectedOrganizationId = organizationId;
      this.getAnalytics();
    },

    onStoreIdSelected(storeId) {
      this.numberOfStores = 0;
      this.selectedStoreId = storeId;
      this.getAnalytics();
    },

    updateCurrentMonth: function (endDate) {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      const month = monthNames[endDate.getMonth()];
      const year = endDate.getFullYear();
      this.currentMonth = `${month} ${year}`;
    },

    onOrganizationsSortChange: function (sortParams) {
      this.organizationsSortParams = sortParams;
      this.getAnalytics();
    },

    onStoresSortChange: function (sortParams) {
      this.storesSortParams = sortParams;
      this.getAnalytics();
    },

    onSellersSortChange: function (sortParams) {
      this.sellersSortParams = sortParams;
      this.getAnalytics();
    },

    onSalesFieldManagerChange: function (salesFieldManagerId) {
      this.salesFieldManagerId = salesFieldManagerId;
      this.getAnalytics();
    },

    resetToBeginning() {
      this.date = null;
      this.getAnalytics();
    },

    retrieveSortParams() {
      if (this.selectedStoreId) {
        return this.sellersSortParams;
      } else if (this.selectedOrganizationId) {
        return this.storesSortParams;
      } else {
        return this.organizationsSortParams;
      }
    },
  },

  watch: {
    date: function (newDate) {
      if (Array.isArray(newDate) && newDate.length > 0 && newDate[0] && newDate[1]) {
        this.updateCurrentMonth(newDate[1]);
      }
      this.getAnalytics();
    },
  },
};
</script>
