import { securedAxiosInstance } from "../axios";
import { isObjectValid } from "@/utils/validation";
import { toSnakeCase } from "@/utils/utils";

class BackofficeService {
  getOrganizations(params) {
    return securedAxiosInstance.get(`/backoffice/organizations`, {
      params,
    });
  }

  getOrganizationOptions() {
    return securedAxiosInstance.get("/backoffice/organizations/options");
  }

  createOrganization(params) {
    return securedAxiosInstance.post(`/backoffice/organizations`,toSnakeCase(params));
  }

  getOrganization(organizationId) {
    return securedAxiosInstance.get(
      `/backoffice/organizations/${organizationId}`
    );
  }

  getDiscounts() {
    return securedAxiosInstance.get("/backoffice/discounts");
  }

  getChallenges() {
    return securedAxiosInstance.get("/backoffice/challenges");
  }

  getStores(page, keywords) {
    return securedAxiosInstance.get("/backoffice/stores", {
      params: {
        page: page,
        keywords: keywords,
      },
    });
  }

  getStore(storeId) {
    return securedAxiosInstance.get(`/backoffice/stores/${storeId}`);
  }

  updateStoreSalesFieldManager(storeId, salesFieldManagerId) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}`, {
      sales_field_manager_id: salesFieldManagerId,
    });
  }

  getProducts(
    storeId,
    organizationId,
    productTypes,
    vendors,
    offerStatuses,
    approvalStatuses,
    tags,
    categories,
    keywords,
    title,
    source,
    parents,
    variants,
    online,
    offline,
    page
  ) {
    const data = {
      filtering: {
        product_types: productTypes,
        vendors: vendors,
        offer_statuses: offerStatuses,
        approval_statuses: approvalStatuses,
        tags: tags,
        categories: categories,
      },
      keywords: keywords,
      title: title,
      source: source,
      parents: parents,
      variants: variants,
      online: online,
      offline: offline,
      page: page,
      store_ids: isObjectValid(storeId) ? [storeId] : null,
      organization_ids: isObjectValid(organizationId) ? [organizationId] : null,
    };
    return securedAxiosInstance.post(`/backoffice/products`, data);
  }

  getLeads(page) {
    return securedAxiosInstance.get(`/backoffice/leads?page=${page}`);
  }

  getProductById(storeId, productId) {
    return securedAxiosInstance.get(`/backoffice/products/${productId}`);
  }

  createDiscount(discountType, title, offeredMonths, finalPrice) {
    const data = {
      discountType: discountType,
      title: title,
      offeredMonths: offeredMonths,
      finalPrice: finalPrice,
    };
    return securedAxiosInstance.post("/backoffice/discounts", data);
  }

  createChallenge(data) {
    return securedAxiosInstance.post("/backoffice/challenges", data);
  }

  executeActionOnProducts(products_id, action_type) {
    const data = {
      products_id: products_id,
      action_type: action_type,
    };
    return securedAxiosInstance.put("/backoffice/products/action", data);
  }

  executeActionOnClaims(claim_id, action_type) {
    const data = {
      claim_id: claim_id,
      action_type: action_type,
    };
    return securedAxiosInstance.put("/backoffice/claims/action", data);
  }

  productPlatformUpdate(
    enabled,
    products_id,
    allProductsSelected,
    approvedProductsIds
  ) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      enabled ? "publish_products" : "unpublish_products"
    );
  }

  matchProducts(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      "match_products"
    );
  }

  approveMatch(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      "approve_and_match"
    );
  }

  approveMatchPublish(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      "approve_match_and_publish_products"
    );
  }

  unpublishProducts(products_id, allProductsSelected, approvedProductsIds) {
    this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      "unpublish_products"
    );
  }

  unmatchProducts(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      "unmatch_products"
    );
  }

  unapproveProducts(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      "unapprove_products"
    );
  }

  approveProducts(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      "approve_products"
    );
  }

  updateProductCategory(product, newCategory) {
    const data = {
      product_id: product.id,
      new_category: newCategory,
    };
    return securedAxiosInstance.put("/backoffice/products/category", data);
  }

  getClaims({ page, status, keywords, organizations, stores }) {
    const data = {
      organizations: organizations,
      stores: stores,
      status: status,
      page: page,
      keywords: keywords,
    };

    return securedAxiosInstance.post(`/backoffice/claims`, data);
  }

  getClaimByFriendlyId(friendlyId) {
    return securedAxiosInstance.get(`/backoffice/claims/${friendlyId}`);
  }

  updateLeadDiscount(leadId, discountId) {
    const data = {
      lead_action: "discount_link",
      discount_id: discountId,
    };
    return securedAxiosInstance.put(`/backoffice/leads/${leadId}`, data);
  }

  disableLead(leadId) {
    const data = {
      lead_action: "disable",
    };
    return securedAxiosInstance.put(`/backoffice/leads/${leadId}`, data);
  }

  updateStoreDiscount(storeId, discountId) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}`, {
      discount_id: discountId,
    });
  }

  updateChallenge(storeId, challengeId) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}`, {
      challenge_id: challengeId,
    });
  }

  generateInsurerReport(startDate, endDate, insurerId, contractCategory) {
    const data = {
      startDate: startDate,
      endDate: endDate,
      insurerId: insurerId,
      contractCategory: contractCategory,
    };
    return securedAxiosInstance.post(
      "/backoffice/insurer/plan_sales/report",
      data
    );
  }

  generateInsurerClaimsReport(startDate, endDate, insurerId, contractCategory) {
    const data = {
      startDate: startDate,
      endDate: endDate,
      insurerId: insurerId,
      contractCategory: contractCategory,
    };
    return securedAxiosInstance.post("/backoffice/insurer/claims/report", data);
  }

  generatePlanSalesReport() {
    return securedAxiosInstance.get("/backoffice/report/plan_sales");
  }

  getPlanSales(data) {
    return securedAxiosInstance.post("/backoffice/plan_sales", data);
  }

  getPlanSale(planSaleId) {
    return securedAxiosInstance.get(`/backoffice/plan_sales/${planSaleId}`);
  }

  cancelPlanSale(
    planSaleId,
    cancellationType,
    cancellationReason,
    feedbackMessage
  ) {
    return securedAxiosInstance.put(`/backoffice/plan_sales/${planSaleId}`, {
      plan_sale: {
        status: "cancelled",
        cancellation_type: cancellationType,
        cancellation_reason: cancellationReason,
        feedback_message: feedbackMessage,
      },
    });
  }

  updatePlanSale(planSaleId, planSaleInformations) {
    return securedAxiosInstance.put(`/backoffice/plan_sales/${planSaleId}`, {
      plan_sale: planSaleInformations,
    });
  }

  getPlanSaleCancellationReasons() {
    return securedAxiosInstance.get("/backoffice/cancellation_reasons");
  }

  getPlanSaleBillingInvoice(planSaleId, planSaleBillingId) {
    return securedAxiosInstance.get(
      `/backoffice/plan_sales/${planSaleId}/plan_sale_billings/${planSaleBillingId}`
    );
  }

  getSellers() {
    return securedAxiosInstance.get("/backoffice/sellers");
  }

  updateSeller(seller) {
    const { id, ...sellerData } = seller;
    return securedAxiosInstance.put(`/backoffice/sellers/${id}`, {
      seller: toSnakeCase(sellerData),
    });
  }

  getInvoices(page, filtering_data) {
    return securedAxiosInstance.post(
      `/backoffice/invoices?page=${page}`,
      filtering_data
    );
  }

  getInsurers() {
    return securedAxiosInstance.get(`/backoffice/insurers`);
  }

  markInvoiceAsPaid(invoice) {
    return securedAxiosInstance.put(
      `/backoffice/invoices/paid?invoiceId=${invoice.id}`
    );
  }

  getContractCategories() {
    return securedAxiosInstance.get("/backoffice/contract_categories");
  }

  createStore(storeData) {
    return securedAxiosInstance.post("/backoffice/stores", toSnakeCase(storeData));
  }

  configureStore(storeData) {
    return securedAxiosInstance.post("/backoffice/stores/configure", toSnakeCase(storeData));
  }

  getLeadMetricsData() {
    return securedAxiosInstance.get("/backoffice/metrics/leads");
  }

  getPlanSalesData() {
    return securedAxiosInstance.get("/backoffice/metrics/plan_sales");
  }

  getOnlineOrdersData(startDate, endDate) {
    return securedAxiosInstance.get(
      `/backoffice/metrics/orders?&start=${startDate}&end=${endDate}`
    );
  }

  updateClaimStatus(params) {
    return securedAxiosInstance.put(`/backoffice/claims/status`, params);
  }

  uploadClaimInvoice(claim, repairCost, invoiceFile) {
    let formData = new FormData();
    formData.append("invoice_file", invoiceFile);
    formData.append("repair_cost", repairCost);

    return securedAxiosInstance.post(
      `/backoffice/claims/${claim.friendlyId}/invoice`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  uploadQuotationInvoice(claim, repairCost, quotationFile) {
    let formData = new FormData();
    formData.append("quotation_file", quotationFile);
    formData.append("repair_cost", repairCost);

    return securedAxiosInstance.post(
      `/backoffice/claims/${claim.friendlyId}/quotation`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  getRenewPlanSaleLink(planSaleId) {
    return securedAxiosInstance.get(
      `/backoffice/plan_sales/${planSaleId}/renewal_link`
    );
  }

  renewPlanSale(planSaleId) {
    return securedAxiosInstance.put(
      `/backoffice/plan_sales/${planSaleId}/renewal`
    );
  }

  sendInvoicesReport({ email, startDate, endDate }) {
    return securedAxiosInstance.get("/backoffice/invoices/reporting", {
      params: {
        email: email,
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  getUsers({ storeId, organizationId }) {
    return securedAxiosInstance.get(`/backoffice/users`, {
      params: {
        store_id: storeId,
        organization_id: organizationId,
      },
    });
  }

  getUser({ userId, storeId, organizationId }) {
    return securedAxiosInstance.get(`/backoffice/users/${userId}`, {
      params: {
        store_id: storeId,
        organization_id: organizationId,
      },
    });
  }

  updateUser({ userId, storeId, organizationId }, data) {
    return securedAxiosInstance.put(`/backoffice/users/${userId}`, {
      user: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        role: data.role,
        store_ids: data.store_ids,
      },
      store_id: storeId,
      organization_id: organizationId,
    });
  }

  deleteUser({ userId, storeId, organizationId }) {
    return securedAxiosInstance.delete(`/backoffice/users/${userId}`, {
      params: {
        store_id: storeId,
        organization_id: organizationId,
      },
    });
  }

  newUserFields({ storeId, organizationId }, data) {
    return securedAxiosInstance.get(`/backoffice/users/new`, {
      params: {
        profile_type: data.profile_type,
        store_id: storeId,
        organization_id: organizationId,
      },
    });
  }

  createUser({ storeId, organizationId }, data) {
    return securedAxiosInstance.post(`/backoffice/users`, {
      user: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        role: data.role,
        store_ids: data.store_ids,
        password: data.password,
      },
      store_id: storeId,
      organization_id: organizationId,
      profile_type: data.profile_type,
    });
  }
}

export default new BackofficeService();
