<template>
  <div class="mt-10">
    <h2 class="text-3xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
      {{ "Plan Sale Billing Details" }}
    </h2>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          class="inline-block min-w-full py-2 pb-12 align-middle md:px-6 lg:px-8"
        >
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Montant
                  </th>
                  <th
                    v-for="commission in planSaleBillings[0].commissions"
                    :key="commission.subjectId"
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ commission.subjectType }}
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Estaly
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Période
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Statut du paiement
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Facture
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="planSaleBilling in this.planSaleBillings"
                  :key="planSaleBilling.id"
                >
                  <td
                    :class="[
                      'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900',
                      planSaleBilling.billingType === 'collected'
                        ? 'text-green-500'
                        : 'text-red-500',
                    ]"
                  >
                    {{
                      planSaleBilling.billingType === "collected" ? "+ " : "- "
                    }}{{ planSaleBilling.finalPrice }}
                  </td>
                  <td
                    v-for="commission in planSaleBilling.commissions"
                    :key="commission.subjectId"
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <span
                      :class="[
                        'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium',
                        commission.subjectType === 'Store'
                          ? 'bg-purple-100 text-purple-700'
                          : 'bg-gray-100 text-gray-600',
                      ]"
                      >{{ commission.amount }} €</span
                    >
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium"
                  >
                    <span
                      class="text-blue-900 bg-blue-100 px-2 py-1 rounded-xl text-xs"
                      >{{ planSaleBilling.estalyCommission }} €</span
                    >
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    {{ planSaleBilling.startDate }} au
                    {{ planSaleBilling.endDate }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <FieldSpan
                      v-if="planSaleBilling.uncollectible == true"
                      :styleToAdd="
                        getPaymentStatusFieldDisplayStyle('uncollectible')
                      "
                      value="uncollectible"
                    />
                    <FieldSpan
                      v-else
                      :styleToAdd="
                        getPaymentStatusFieldDisplayStyle(
                          planSaleBilling.paymentStatus
                        )
                      "
                      :value="planSaleBilling.paymentStatus"
                    />
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <div
                      v-if="planSaleBilling.uncollectible === true"
                      class="flex"
                    >
                      <div class="bg-gray-100 rounded-xl flex px-2 py-1.5">
                        <BanIcon
                          class="h-6 w-6 text-gray-800"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div
                      v-else-if="planSaleBilling.discountApplied"
                      class="flex"
                    >
                      <GiftIcon
                        class="h-6 w-6 text-primary-dark cursor-pointer"
                        aria-hidden="true"
                      />
                      <span
                        class="pl-2 text-base font-semibold text-primary-dark"
                        >Offert</span
                      >
                    </div>
                    <div
                      v-else-if="planSaleBilling.paymentStatus === 'succeeded'"
                      class="flex"
                    >
                      <CloudDownloadIcon
                        v-if="planSaleBilling.invoiceUrl"
                        class="h-6 w-6 text-primary-dark cursor-pointer"
                        aria-hidden="true"
                      />
                      <a
                        v-if="planSaleBilling.invoiceUrl"
                        @click.prevent="handleDownload(planSaleBilling)"
                        class="pl-2 text-base font-semibold text-primary-dark cursor-pointer"
                        target="_blank"
                      >
                        {{ $t("Download") }}
                      </a>
                    </div>
                    <div
                      v-else-if="
                        ['failed', 'disputed'].includes(
                          planSaleBilling.paymentStatus
                        )
                      "
                      class="flex"
                    >
                      <div class="bg-secondary rounded-xl flex px-2 py-1.5">
                        <CreditCardIcon
                          v-if="planSaleBilling.invoicePaymentUrl"
                          class="h-6 w-6 text-black cursor-pointer"
                          aria-hidden="true"
                        />
                        <a
                          v-if="planSaleBilling.invoicePaymentUrl"
                          @click.prevent="handlePayment(planSaleBilling)"
                          class="flex items-center pl-2 text-base font-semibold text-black uppercase cursor-pointer"
                          target="_blank"
                        >
                          Payer
                        </a>
                      </div>
                    </div>
                    <div
                      v-else
                      class="px-3 py-0.5 rounded-full text-sm font-medium bg-secondary"
                    >
                      <p>Bientôt disponible</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import {
  CloudDownloadIcon,
  GiftIcon,
  CreditCardIcon,
  BanIcon,
} from "@heroicons/vue/outline";
import BackofficeService from "@/api/services/backoffice";

export default {
  props: ["planSaleBillings", "planSaleId"],

  components: {
    FieldSpan,
    CloudDownloadIcon,
    GiftIcon,
    CreditCardIcon,
    BanIcon,
  },

  data: () => {
    return {
      openModal: false,
      commissionPlanSaleBilling: null,
    };
  },

  methods: {
    openModalAction: function (planSaleBilling) {
      this.openModal = true;
      this.commissionPlanSaleBilling = planSaleBilling;
    },

    closeModalAction: function () {
      this.openModal = false;
      this.commissionPlanSaleBilling = null;
    },

    getPaymentStatusFieldDisplayStyle: function (paymentStatus) {
      switch (paymentStatus) {
        case "waiting":
          return "bg-yellow-100 text-yellow-800";
        case "succeeded":
          return "bg-green-100 text-green-800";
        case "failed":
        case "disputed":
          return "bg-red-100 text-red-800";
        case "uncollectible":
          return "bg-gray-100 text-gray-800";
        default:
          return "";
      }
    },

    handleDownload(planSaleBilling) {
      BackofficeService.getPlanSaleBillingInvoice(
        this.planSaleId,
        planSaleBilling.id
      )
        .then((response) => {
          const url = response.data.invoiceUrl;
          if (url) window.open(url, "_blank");
        })
        .catch((error) => {
          console.error("Error fetching the invoice URL:", error);
        });
    },

    handlePayment(planSaleBilling) {
      BackofficeService.getPlanSaleBillingInvoice(
        this.planSaleId,
        planSaleBilling.id
      )
        .then((response) => {
          const url = response.data.invoicePaymentUrl;
          if (url) window.open(url, "_blank");
        })
        .catch((error) => {
          console.error("Error fetching the payment URL:", error);
        });
    },
  },
};
</script>
