<template>
  <div class="mt-12 bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Variants</h1>
        <p class="mt-2 text-sm text-gray-700">
          {{ $t("A list of all the variants of the products") }}
        </p>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {{ $t("Name") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Category") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Price incl. taxes") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Source") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Reference") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Contracts") }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="variant in variants" :key="variant.id">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="h-10 w-10 flex-shrink-0">
                        <img
                          class="h-10 w-10 rounded-full"
                          :src="variant.imageUrl"
                          alt=""
                        />
                      </div>
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          <p>{{ variant.title }}</p>
                          <FieldSpan
                            v-bind:style-to-add="
                              getEnabledFieldDisplayStyle(variant.enabled)
                            "
                            v-bind:value="variant.enabled"
                          />
                          <FieldSpan
                            class="ml-3"
                            v-bind:style-to-add="
                              getStatusFieldDisplayStyle(variant.status)
                            "
                            v-bind:value="variant.status"
                          />
                        </div>
                        <div class="text-gray-500">{{ variant.vendor }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{
                        this.capitalizeFirstLetter(
                          this.$t(`${variant.category}`)
                        )
                      }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{
                        this.formatPrice(
                          variant.price.amount,
                          variant.price.currencyCode
                        )
                      }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ capitalizeFirstLetter(variant.source) }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ getReferenceId(variant) }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <GenericButton
                      class="border-primary"
                      @onClick="this.showVariantInformations(variant)"
                    >
                      <EyeIcon class="h-5 w-5" aria-hidden="true" />
                    </GenericButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VariantDetails
    v-bind:variant="variantToShow"
    @close="closeVariantDetailsModal"
  />
</template>
<script>
import { EyeIcon } from "@heroicons/vue/solid";
import { isStringValid } from "@/utils/validation";
import GenericButton from "@/components/utils/GenericButton.vue";
import { formatPrice } from "@/utils/price_formatter";
import { capitalizeFirstLetter } from "@/utils/utils";
import VariantDetails from "@/views/products/details/variant/details/VariantDetails.vue";
import FieldSpan from "@/components/utils/FieldSpan.vue";

export default {
  components: {
    FieldSpan,
    GenericButton,
    EyeIcon,
    VariantDetails,
  },
  data: () => {
    return {
      variantToShow: null,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return capitalizeFirstLetter(string);
    },
    showVariantInformations: function (variant) {
      this.variantToShow = variant;
    },
    closeVariantDetailsModal: function () {
      this.variantToShow = null;
    },
    getReferenceId: function (variant) {
      return isStringValid(variant.referenceId)
        ? variant.referenceId.split("/").at(-1)
        : "";
    },
    formatPrice: function (price, currencyCode) {
      return formatPrice("fr-FR", price, currencyCode);
    },
    getStatusFieldDisplayStyle: function (status) {
      let result;
      switch (status) {
        case "Pending":
          result = "bg-yellow-100 text-yellow-800";
          break;
        case "Live":
          result = "bg-green-100 text-green-800";
          break;
        case "Matched":
          result = "bg-blue-100 text-blue-800";
          break;
        case "Refused":
          result = "bg-red-100 text-red-800";
          break;
        default:
          result = "bg-blue-100 text-blue-800";
      }
      return result;
    },
    getEnabledFieldDisplayStyle: function (isEnabled) {
      return isEnabled
        ? "bg-green-100 text-green-800"
        : "bg-red-100 text-red-800";
    },
  },
  props: ["variants", "productId"],
};
</script>
