<template>
  <Modal :open="open" @close="emitClose">
    <VeeForm
      v-slot="{ handleSubmit }"
      :validation-schema="schema"
      as="div"
    >
      <form @submit="handleSubmit($event, emitSendInvoicesReport)">
        <div>
          <p class="font-bold text-lg">{{ $t("Send invoices report by mail") }}</p>
          <p class="italic">{{ $t("The download link expires after 1 hour.")}}</p>
          <label
            for="email"
            class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Email
          </label>
          <Field
            id="email"
            name="email"
            type="email"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
            placeholder="office@estaly.co"
            v-model="this.email"
          />
          <ErrorMessage
            name="email"
            class="mt-2 text-sm text-red-600"
          />
          <button
            type="submit"
            class="mt-4 flex justify-center text-button-font-primary bg-primary uppercase rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            Envoyer
          </button>
        </div>
      </form>
    </VeeForm>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().required("Email is required").email("Email is wrong"),
});

export default {
  components: {
    Modal,
    ErrorMessage, 
    Field,
    VeeForm
  },

  data() {
    return {
      schema,
      email: "",
    };
  },

  props: ["open"],

  methods: {
    emitClose: function () {
      this.email = "";
      this.$emit("close");
    },

    emitSendInvoicesReport: function () {
      this.$emit("sendInvoicesReport", this.email);
    },
  },
};
</script>
