<template>
  <div>
    <div class="flex flex-rows mt-10">
      <div>
        <h2 class="block text-sm font-medium text-gray-900">Sales Targets</h2>
      </div>
      <div>
        <span
          class="ml-5 relative z-0 inline-flex shadow-sm rounded-md space-x-3"
        >
          <div
            class="relative inline-flex rounded-full p-1 bg-gray-200 text-white shadow-sm hover:bg-gray-100 cursor-pointer"
            @click="addSalesTarget"
          >
            <PlusIcon class="h-2 w-2" aria-hidden="true" />
          </div>
        </span>
      </div>
    </div>
    <div>
      <div
        v-for="(salesTarget, index) in salesTargets"
        :key="'salesTarget-' + index"
      >
        <div
          class="ml-4 flex flex-col md:flex-row items-center space-x-0 md:space-x-10"
        >
          <div class="flex flex-col">
            <label class="text-xs font-medium leading-6 text-gray-900 sm:pt-1.5"
              >Target</label
            >
            <input
              class="w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              type="number"
              v-model="salesTarget.target"
              :disabled="!salesTarget.isEditable"
              :class="{ 'bg-gray-200': !salesTarget.isEditable }"
              placeholder="Objectif"
            />
          </div>

          <div class="flex flex-col">
            <label class="text-xs font-medium leading-6 text-gray-900 sm:pt-1.5"
              >Reward</label
            >
            <input
              class="w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              type="number"
              v-model="salesTarget.reward"
              :disabled="!salesTarget.isEditable"
              :class="{ 'bg-gray-200': !salesTarget.isEditable }"
              placeholder="Récompense"
            />
          </div>

          <div
            class="mt-2 cursor-pointer"
            @click="saveSalesTarget(salesTarget)"
          >
            <CheckIcon class="h-6 w-6" aria-hidden="true" />
          </div>

          <div
            class="mt-2 cursor-pointer"
            @click="removeSalesTarget(index, salesTarget)"
          >
            <TrashIcon class="h-6 w-6" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusIcon, TrashIcon, CheckIcon } from "@heroicons/vue/solid";

export default {
  components: {
    PlusIcon,
    TrashIcon,
    CheckIcon,
  },

  data() {
    return {
      salesTargets: [],
    };
  },
  methods: {
    addSalesTarget() {
      this.salesTargets.push({
        target: "",
        reward: "",
        isEditable: true,
      });
    },
    removeSalesTarget(index, salesTarget) {
      this.salesTargets.splice(index, 1);
      this.$emit("remove:salesTargets", salesTarget);
    },
    saveSalesTarget(salesTarget) {
      this.$emit("update:salesTargets", salesTarget);
      salesTarget.isEditable = false;
    },
  },
};
</script>
