<template>
  <div>
    <SalesReport />
    <PlanSalesReport />
    <ClaimsReport />
  </div>
</template>

<script>
import SalesReport from "@/views/report/sales/SalesReport.vue";
import ClaimsReport from "@/views/report/insurer/ClaimsReport.vue";
import PlanSalesReport from "@/views/report/insurer/PlanSalesReport.vue";

export default {
  components: { PlanSalesReport, ClaimsReport, SalesReport },
};
</script>
