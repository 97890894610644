export function getDefaultErrorMessageContent(locale) {
  return {
    category: "simple",
    type: "error",
    title: getDefaultErrorMessageTitle(locale),
    text: getDefaultErrorMessageText(locale),
  };
}

export function getDefaultErrorMessageText(locale) {
  switch (locale) {
    case "fr":
      return "Nos équipes ont été notifiées et se chargent de résoudre ce problème le plus rapidement possible";
    default:
      return "Our teams have been notified and will do everything possible to correct this as soon as possible";
  }
}

export function getDefaultErrorMessageTitle(locale) {
  switch (locale) {
    case "fr":
      return "Une erreur s'est produite";
    default:
      return "Something went wrong";
  }
}
