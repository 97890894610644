<template>
  <div class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("Claim Information") }}
      </h3>
    </div>
    <div class="border-t border-gray-200">
      <dl class="bg-white px-4 sm:px-6 py-5 grid grid-cols-2 gap-x-4 gap-y-6">
        <div>
          <dt class="text-sm font-bold text-gray-500">Nom de la boutique</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ claim.salesChannel }}</dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            Email du Claim Manager
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ claim.storeManagerEmail }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Prénom du client</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ claim.customerFirstName }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Nom du client</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ claim.customerLastName }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            Adresse e-mail du client
          </dt>
          <dd class="mt-1 text-sm text-gray-900">{{ claim.customerEmail }}</dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Nom du produit</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ claim.productTitle }}</dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">Date de déclaration</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ this.getClaimFormattedFiledDate }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/date_formatter";

export default {
  props: ["claim"],
  computed: {
    getClaimFormattedFiledDate() {
      return formatDate(this.claim.filedDate, this.$i18n.locale);
    },
  },
};
</script>
