<template>
  <div>
    <div>
      <div>
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a
              v-for="tab in tabs"
              :key="tab.name"
              @click.prevent="selectTab(tab)"
              :class="[
                tab.current
                  ? 'border-primary text-primary-dark'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
              ]"
              >{{ tab.name }}</a
            >
          </nav>
        </div>
      </div>
    </div>

    <div v-if="currentTab === 'Suivi des rémunérations'">
      <BusinessAnalytics />
    </div>
    <div v-else-if="currentTab === 'Suivi des ventes'">
      <RetailAnalytics />
    </div>
  </div>
</template>

<script>
import BusinessAnalytics from "@/components/analytics/business/Analytics.vue";
import RetailAnalytics from "@/components/analytics/retail/Analytics.vue";

export default {
  components: {
    BusinessAnalytics,
    RetailAnalytics,
  },

  data: () => ({
    tabs: [
      { name: "Suivi des rémunérations", current: true },
      { name: "Suivi des ventes", current: false },
    ],
    currentTab: "Suivi des rémunérations",
  }),

  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.current = tab.name === selectedTab.name;
      });
      this.currentTab = selectedTab.name;
    },
  },
};
</script>
