<template>
  <div v-if="userList" class="bg-gray-100 shadow overflow-hidden sm:rounded-lg">
    <div
      @click="toggleVisibility"
      class="flex justify-between items-center px-4 py-5 sm:px-6 cursor-pointer"
    >
      <h3 class="text-lg leading-6 font-bold text-gray-900">Utilisateurs</h3>
      <div class="w-10">
        <ChevronDownIcon v-if="!isVisible" />
        <ChevronUpIcon v-else />
      </div>
    </div>
    <div v-if="isVisible" class="flex flex-col">
      <div class="flex justify-end p-2">
        <GenericButton @click="() => openCreateModal()">
          {{ $t("Add a user") }}
        </GenericButton>
      </div>
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Id") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Name") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Email") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Role") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Status") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  />
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  />
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 text-xs">
                <tr
                  v-for="(manager, index) in usersArray"
                  :key="index"
                  v-bind:class="'hover:bg-gray-100'"
                >
                  <td class="px-3 py-3.5 font-bold">
                    {{ manager.user_id }}
                  </td>
                  <td class="px-3 py-3.5">
                    {{ manager.first_name + " " + manager.last_name }}
                  </td>
                  <td class="px-3 py-3.5">
                    {{ manager.email }}
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-bind:style-to-add="'bg-blue-400 text-white'"
                      v-bind:value="manager.role"
                      :translation-prefix="'roles'"
                    />
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-bind:style-to-add="[
                        'text-white',
                        manager.account_created ? 'bg-green-400' : 'bg-red-400',
                      ]"
                      v-bind:value="
                        manager.account_created ? 'Actif' : 'Inactif'
                      "
                    />
                  </td>
                  <td class="px-3 py-3.5">
                    <PencilIcon
                      class="h-5 cursor-pointer"
                      v-if="manager.role !== 'organizationmanager'"
                      @click="() => openUpdateModal(manager.user_id)"
                    />
                  </td>
                  <td class="px-3 py-3.5">
                    <TrashIcon
                      class="h-5 cursor-pointer"
                      v-if="manager.role !== 'organizationmanager'"
                      @click="() => deleteUser(manager.user_id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <UpdateUserModal
        :open-modal="openedUpdateModal"
        :edited-user-id="editedUserId"
        @closeModal="closeUpdateModal"
        @userListUpdated="refreshStoreUsers"
        :store-id="storeId"
      />
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";
import GenericButton from "@/components/utils/GenericButton.vue";
import UpdateUserModal from "@/views/stores/details/users/UpdateUserModal.vue";
import { toSnakeCase } from "@/utils/utils";
import { mapActions } from "vuex";
import BackofficeService from "@/api/services/backoffice";
import { PencilIcon, TrashIcon } from "@heroicons/vue/outline";

export default {
  components: {
    UpdateUserModal,
    GenericButton,
    ChevronUpIcon,
    ChevronDownIcon,
    FieldSpan,
    PencilIcon,
    TrashIcon,
  },

  props: ["users", "storeId"],

  data() {
    return {
      userList: toSnakeCase(this.users),
      isVisible: false, // Initial visibility state
      openedUpdateModal: false,
      editedUserId: null,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    toggleVisibility() {
      this.isVisible = !this.isVisible; // Toggle the visibility state
    },

    openUpdateModal(user) {
      this.openedUpdateModal = true;
      this.editedUserId = user;
    },

    closeUpdateModal() {
      this.openedUpdateModal = false;
      this.editedUserId = null;
    },

    async openCreateModal() {
      this.openedUpdateModal = true;
      this.editedUserId = null;
    },

    async refreshStoreUsers() {
      this.openedUpdateModal = false;
      const response = await BackofficeService.getUsers({
        storeId: this.storeId,
      });
      this.userList = toSnakeCase(response.data.users);
    },

    async deleteUser(userId) {
      if (!confirm("Do you really want to delete this user?")) {
        return;
      }

      try {
        await BackofficeService.deleteUser({
          userId,
          storeId: this.storeId,
        });
        this.notify({
          category: "simple",
          type: "success",
          title: "User deleted successfully",
        });
        this.refreshStoreUsers();
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "User could not be deleted",
          text: error.response.data.errors.join(", "),
        });
      }
    },
  },

  computed: {
    usersArray() {
      const store_managers = this.userList.store_managers || [];
      const organization_managers = this.userList.organization_managers || [];

      return store_managers.concat(organization_managers);
    },
  },
};
</script>
