<template>
  <div>
    <div class="flex content-center">
      <h3 class="text-xl pb-4 pl-2 font-bold tracking-tight text-gray-900 mr-4">
        Commandes online
      </h3>
      <Switch
        v-model="enabled"
        :class="[
          enabled ? 'bg-primary' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
        ]"
      >
        <span class="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          :class="[
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
    </div>

    <div v-if="enabled" class="flex flex-col">
      <div class="flex">
        <div class="flex flex-col sm:w-1/4 sm:flex-row w-full">
          <Datepicker
            class="text-sm w-full rounded-md"
            v-model="date"
            range
            multiCalendars
            v-bind:presetRanges="presetRanges"
            autoApply
            v-bind:enableTimePicker="false"
            v-bind:format="format"
          />
        </div>
      </div>
      <table class="table-auto mt-4">
        <thead>
          <tr>
            <th class="px-4 py-2">Sales Channel</th>
            <th
              v-for="channelData in onlineOrdersData"
              :key="channelData.sales_channel"
              class="px-4 py-2"
            >
              {{ channelData.sales_channel }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Use v-for to loop through metrics and display rows for each metric -->
          <tr v-for="(value, key) in onlineOrdersData[0].data" :key="key">
            <td class="border px-4 py-2">{{ formatMetricsName(key) }}</td>
            <td
              v-for="channelData in onlineOrdersData"
              :key="channelData.sales_channel"
              class="border px-4 py-2"
            >
              {{ channelData.data[key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import Datepicker from "@vuepic/vue-datepicker";
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  startOfWeek,
  subWeeks,
  endOfWeek,
} from "date-fns";
import { Switch } from "@headlessui/vue";
import { mapMutations } from "vuex";

export default {
  components: { Switch, Datepicker },
  computed: {
    tableHeaders() {
      if (this.onlineOrdersData.length > 0) {
        return Object.keys(this.onlineOrdersData[0].data);
      }
      return [];
    },
  },
  data() {
    return {
      onlineOrdersData: [{ data: null }],
      enabled: false,
      date: [],
      presetRanges: [
        {
          id: 1,
          label: "This Month",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "This Week",
          range: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            endOfWeek(new Date(), { weekStartsOn: 1 }),
          ],
        },
        {
          id: 3,
          label: "Last Month",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          id: 4,
          label: "Last Week",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
        {
          id: 5,
          label: "Since launch",
          range: [new Date("2022-09-16"), new Date()],
        },
      ],
    };
  },

  watch: {
    enabled: function (newValue) {
      if (newValue === true) {
        this.getOnlineOrdersData();
      } else {
        this.onlineOrdersData = [];
      }
    },
    date: function () {
      if (this.enabled) {
        this.getOnlineOrdersData();
      }
    },
  },

  created() {
    const endDate = endOfMonth(new Date());
    const startDate = startOfMonth(new Date());
    this.date = [startDate, endDate];
  },

  methods: {
    ...mapMutations("backoffice", ["setIsLoading"]),

    getOnlineOrdersData: async function () {
      this.setIsLoading(true);
      const start = this.date[0];
      const end = this.date[1];
      const response = await BackofficeService.getOnlineOrdersData(start, end);
      this.onlineOrdersData = response.data.orderMetricsData;
      this.setIsLoading(false);
    },

    formatMetricsName: function (name) {
      let transformedStr = name.replace(/_/g, " "); // Remplace le caractère de soulignement par un espace
      transformedStr =
        transformedStr.charAt(0).toUpperCase() + transformedStr.slice(1); // Met le premier caractère en majuscule
      return transformedStr;
    },
  },
};
</script>
