<template>
  <div
    v-if="this.technicalConfiguration"
    class="bg-gray-1O0 shadow overflow-hidden sm:rounded-lg"
  >
    <div
      @click="this.toggleVisibility"
      class="bg-gray-100 flex justify-between items-center px-4 py-5 sm:px-6 cursor-pointer"
    >
      <h3 class="text-lg leading-6 font-bold text-gray-900">
        Configuration technique
      </h3>
      <div class="w-10">
        <ChevronDownIcon v-if="!this.isVisible" />
        <ChevronUpIcon v-else />
      </div>
    </div>
    <div v-if="this.isVisible" class="border-t border-gray-200">
      <dl class="bg-white px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Offer cache expiration time") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p v-if="this.technicalConfiguration.offerCacheExpirationTime">
              {{ this.technicalConfiguration.offerCacheExpirationTime }}
            </p>
            <p class="font-bold" v-else>—</p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Matching by categories enabled") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p v-if="this.technicalConfiguration.matchingByCategoriesEnabled">
              {{ this.technicalConfiguration.matchingByCategoriesEnabled }}
            </p>
            <p class="font-bold" v-else>—</p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Record a sale") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ this.technicalConfiguration.recordASale }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Send matched contracts without products") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <p
              v-if="
                this.technicalConfiguration.sendMatchedContractsWithoutProducts
              "
            >
              {{
                this.technicalConfiguration.sendMatchedContractsWithoutProducts
              }}
            </p>
            <p class="font-bold" v-else>—</p>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Automatic matched contract synchronization enabled") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              this.technicalConfiguration
                .automaticMatchedContractSynchronizationEnabled
            }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronUpIcon, ChevronDownIcon },
  props: ["technicalConfiguration"],

  data() {
    return {
      isVisible: false, // Initial visibility state
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible; // Toggle the visibility state
    },
  },
};
</script>
