<template>
  <div>
    <div v-if="showSuccessMessage">
      <p class="text-xs text-green-400">Copied!</p>
    </div>
    <div
      class="p-1 hover:bg-gray-100 rounded-md cursor-pointer"
      v-on:click="copyToClipboard"
      v-else
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["textToCopy"],

  data() {
    return {
      showSuccessMessage: false,
    };
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.textToCopy).then(() => {
        this.showSuccessMessage = true;
        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 1000);
      });
    },
  },
};
</script>
