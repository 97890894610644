<template>
  <Modal v-bind:open="this.open" @close="this.closeModal">
    <div>
      <div>
        <label
          for="claim_status"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Statut</label
        >
        <select
          id="claim_status"
          name="claim_status"
          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
          v-model="this.selectedStatus"
        >
          <option
            v-for="status in this.claim.possibleNextClaimStatuses"
            v-bind:key="status"
          >
            {{ status }}
          </option>
        </select>
      </div>
      <div class="mt-5">
        <label
          for="claim_status"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Type de prise en charge</label
        >
        <select
          id="claim_status"
          name="claim_status"
          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
          v-model="this.selectedHandlingType"
        >
          <option
            v-for="handlingType in this.claim.possibleHandlingTypes"
            v-bind:key="handlingType.value"
            v-bind:value="handlingType"
          >
            {{ handlingType.description }}
          </option>
        </select>
      </div>

      <div class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">
          Estaly Manager Notes
        </p>
        <div class="mt-2">
          <textarea
            rows="4"
            name="comment"
            id="comment"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            v-model="this.estalyManagerNotes"
          />
        </div>
      </div>
      <div class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">
          Claim Manager Notes
        </p>
        <div class="mt-2">
          <textarea
            rows="4"
            name="comment"
            id="comment"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            v-model="this.claimManagerNotes"
          />
        </div>
      </div>
      <NotificationForm
        v-bind:name="'customer'"
        v-bind:selected-status="this.selectedStatus"
        v-bind:predefined-actions="this.claim.predefinedClientActions"
        v-bind:claim="this.claim"
        v-on:update="this.updateClientNotificationData"
      />
      <NotificationForm
        v-bind:name="'merchant'"
        v-bind:selected-status="this.selectedStatus"
        v-bind:predefined-actions="this.claim.predefinedMerchantActions"
        v-bind:claim="this.claim"
        v-on:update="this.updateMerchantNotificationData"
        @updateClaimManager="
          (newValue) => {
            this.selectedClaimManager = newValue;
          }
        "
      />
      <div class="flex justify-center">
        <GenericButton class="mt-5" @onClick="this.update">
          Mettre à jour
        </GenericButton>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/modal/Modal.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import NotificationForm from "@/components/claim/list/details/status/NotificationForm.vue";
import { mapActions } from "vuex";

export default {
  props: ["claim", "open"],
  components: {
    NotificationForm,
    GenericButton,
    Modal,
  },

  watch: {
    selectedStatus: function () {
      this.clearData();
    },
  },

  data: () => {
    return {
      clientNotificationData: null,
      merchantNotificationData: null,
      estalyManagerNotes: null,
      claimManagerNotes: null,
      selectedStatus: null,
      selectedClaimManager: null,
      selectedHandlingType: null,
    };
  },

  beforeMount() {
    this.selectedStatus = this.claim.currentStatus;
    this.selectedHandlingType = this.claim.handlingType;
  },

  methods: {
    ...mapActions("claims", ["updateClaimStatus"]),
    ...mapActions("notifications", ["notify"]),

    updateClientNotificationData: function (newData) {
      this.clientNotificationData = newData;
    },
    updateMerchantNotificationData: function (newData) {
      this.merchantNotificationData = newData;
    },
    update: async function () {
      try {
        await this.updateClaimStatus({
          claim_id: this.claim.friendlyId,
          status: this.selectedStatus,
          status_params: {
            estaly_manager_notes: this.estalyManagerNotes,
            claim_manager_notes: this.claimManagerNotes,
            merchant_notification: this.merchantNotificationData,
            client_notification: this.clientNotificationData,
            selected_claim_manager: this.selectedClaimManager,
            handling_type: this.selectedHandlingType?.value,
          },
        });
      } catch (error) {
        await this.notify({
          category: "simple",
          type: "error",
          title: "Error when trying to refuse the quotation",
          text: error.response.data.error,
        });
      }
      this.closeModal();
    },
    clearData: function () {
      this.clientNotificationData = null;
      this.merchantNotificationData = null;
      this.estalyManagerNotes = null;
    },
    closeModal: function () {
      this.$emit("close");
    },
  },
};
</script>
