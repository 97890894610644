<template>
  <div>
    <Datepicker
      :placeholder="this.placeholder"
      class="text-sm w-full rounded-md"
      v-model="date"
      month-picker
      range
      autoApply
      :enableTimePicker="false"
      locale="fr"
      :max-date="new Date()"
      @update:model-value="handleDateSelected"
      :format="format"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { endOfMonth, startOfMonth, subMonths } from "date-fns";

export default {
  components: { Datepicker },

  props: ["placeholder"],

  data: () => {
    return {
      date: null,
    };
  },

  computed: {
    currentMonth: function () {
      return {
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      };
    },

    previousMonth: function () {
      return {
        start: startOfMonth(subMonths(new Date(), 1)),
        end: endOfMonth(subMonths(new Date(), 1)),
      };
    },
  },

  methods: {
    handleDateSelected: function (selectedRange) {
      if (!selectedRange) {
        this.$emit("newDateSelected", null, null);
        return;
      }

      const rangeStart = selectedRange[0];
      const startDate = startOfMonth(
        new Date(rangeStart.year, rangeStart.month)
      );

      const rangeEnd = selectedRange[1] || rangeStart;
      const endDate = endOfMonth(new Date(rangeEnd.year, rangeEnd.month));

      this.$emit(
        "newDateSelected",
        startDate.toDateString(),
        endDate.toDateString()
      );
    },

    format: function (date) {
      let rangeStart = date[0];
      const startDate = startOfMonth(rangeStart);

      let rangeEnd = date[1] || rangeStart;
      const endDate = endOfMonth(rangeEnd);

      return (
        `Du ${startDate.toLocaleDateString("fr-FR", {
          month: "numeric",
          year: "numeric",
        })} ` +
        `au ${endDate.toLocaleDateString("fr-FR", {
          month: "numeric",
          year: "numeric",
        })}`
      );
    },
  },
};
</script>
