<template>
  <Modal v-bind:open="this.openModal" @close="this.closeModal">
    <div
      class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-primary-light"
    >
      <DocumentReportIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </div>

    <VeeForm
      v-slot="{ handleSubmit }"
      :validation-schema="schema"
      as="div"
      ref="discountCreationForm"
    >
      <form @submit="handleSubmit($event, createChallenge)">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h2 class="text-base font-semibold leading-7 text-gray-900">
              Créer un Challenge
            </h2>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Entrez les informations concernant le nouveau challenge
            </p>

            <div
              class="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0"
            >
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
              >
                <label
                  for="startDate"
                  class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >Start Date</label
                >
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    id="startDate"
                    name="startDate"
                    type="date"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
                    placeholder="Titre de la promotion"
                    v-model="this.startDate"
                  />
                  <ErrorMessage
                    name="startDate"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
              >
                <label
                  for="endDate"
                  class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >End Date</label
                >
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    id="endDate"
                    name="endDate"
                    type="date"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
                    placeholder="Nombre de mois offerts"
                    v-model="this.endDate"
                  />
                  <ErrorMessage
                    name="endDate"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <CreateSalesTarget
          @update:salesTargets="addSalesTarget"
          @remove:salesTargets="removeSalesTarget"
        />

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            class="inline-flex justify-center text-button-font-primary bg-primary uppercase rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            Créer
          </button>
        </div>
      </form>
    </VeeForm>
  </Modal>
</template>
<script>
import CreateSalesTarget from "@/views/challenges/create/CreateSalesTarget.vue";
import Modal from "@/components/modal/Modal.vue";
import { DocumentReportIcon } from "@heroicons/vue/outline";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import * as yup from "yup";
import BackofficeService from "@/api/services/backoffice";
import { mapActions } from "vuex";

const schema = yup.object({
  startDate: yup.date().required("Please specify the start date"),
  endDate: yup.date().required("Please specify the start date"),
});

export default {
  components: {
    Modal,
    DocumentReportIcon,
    VeeForm,
    Field,
    ErrorMessage,
    CreateSalesTarget,
  },

  data() {
    return {
      schema,
      startDate: "",
      endDate: "",
      salesTargets: [],
    };
  },

  computed: {
    isDiscountTypeOffered() {
      return this.selectedDiscountType === "offered";
    },
  },

  props: ["openModal"],

  methods: {
    ...mapActions("notifications", ["notify"]),
    closeModal: function () {
      this.$emit("close");
    },

    createChallenge: function () {
      if (this.salesTargets === []) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Missing Params",
          text: "Please provide sales targets",
        });
      } else {
        try {
          let createData = {
            startDate: this.startDate,
            endDate: this.endDate,
            salesTargets: this.salesTargets,
          };
          BackofficeService.createChallenge(createData);
          this.notify({
            category: "simple",
            type: "success",
            title: "Challenge is created.",
            text: "You can add this challenge to a store.",
          });
        } catch (error) {
          this.notify({
            category: "simple",
            type: "error",
            title: error.response.data.error,
            text:
              error.response.data.message +
              " :" +
              String(error.response.data.error_details),
          });
        }
      }
    },

    addSalesTarget(newValue) {
      this.salesTargets.push(newValue);
    },

    removeSalesTarget(newValue) {
      let salesTarget = this.salesTargets.find(
        (group) =>
          group.target === newValue.target && group.reward === newValue.reward
      );

      if (salesTarget) {
        let index = this.salesTargets.indexOf(salesTarget);

        if (index !== -1) {
          this.salesTargets.splice(index, 1);
        }
      }
    },
  },
};
</script>
