<template>
  <div>
    <SearchBar
      v-bind:current-page="this.currentPage"
      v-bind:placeholder="'Recherche de point de vente par nom/url'"
      v-bind:initial-keywords="this.keywords"
      v-bind:available-filters="[]"
      v-bind:selected-filters="[]"
      @updateKeywordSearch="this.updateKeywordSearch"
    />
    <div class="mt-10 relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr class="hidden md:table-row">
            <th scope="col" class="px-6 py-3">Id</th>
            <th scope="col" class="px-6 py-3">Nom</th>
            <th scope="col" class="px-6 py-3">Organization</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="store in this.stores"
            v-bind:key="store.id"
            @click="this.redirectToStoreDetailsPage(store.id)"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer hover:bg-gray-200"
          >
            <td class="px-6 py-4 hidden md:table-cell">
              {{ store.id }}
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              {{ store.salesChannel }}
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              {{ store.organizationName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      v-bind:currentPage="currentPage"
      v-bind:pages="pages"
      v-bind:startIdx="startIdx"
      v-bind:endIdx="endIdx"
      v-bind:total="this.totalNumberOfStores"
      v-on:changePage="handleChangePage"
      v-if="pages.length > 1"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import BackofficeService from "@/api/services/backoffice";
import Pagination from "@/components/utils/Pagination.vue";
import SearchBar from "@/components/menu/SearchBar.vue";

const PAGE_SIZE = 20;

export default {
  components: {
    SearchBar,
    Pagination,
  },
  async mounted() {
    await this.retrieveStores(this.currentPage, this.keywords);
  },
  data() {
    return {
      stores: [],
      currentPage: 1,
      keywords: null,
      totalNumberOfStores: 1,
    };
  },
  computed: {
    ...mapState("backoffice", ["isLoading"]),
    startIdx: function () {
      return (this.currentPage - 1) * PAGE_SIZE;
    },
    endIdx: function () {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfStores);
    },
    pages: function () {
      if (this.isLoading || this.totalNumberOfStores < PAGE_SIZE) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.totalNumberOfStores / PAGE_SIZE)).keys(),
      ].map((e) => e + 1);
    },
  },
  methods: {
    retrieveStores: async function (page, keywords) {
      const response = await BackofficeService.getStores(page, keywords);
      this.stores = response.data.stores;
      this.totalNumberOfStores = response.data.totalNumberOfStores;
    },
    handleChangePage: function (page) {
      this.currentPage = page;
      this.retrieveStores(page, this.keywords);
    },
    redirectToStoreDetailsPage: function (storeId) {
      this.$router.push(`/stores/${storeId}`);
    },
    updateKeywordSearch: function (keywords) {
      this.keywords = keywords;
      this.handleChangePage(1);
    },
  },
};
</script>
