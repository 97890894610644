<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100"
      >
        Actions
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute overflow-y-auto right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <button
              @click="this.redirectToProductPage"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
                'w-full',
              ]"
            >
              <p class="flex">
                View product<EyeIcon
                  class="h-5 w-5 ml-2 text-primary-dark"
                  aria-hidden="true"
                />
              </p>
            </button>
          </MenuItem>
          <MenuItem v-if="this.isProductApprovable" v-slot="{ active }">
            <button
              @click="this.approveProduct"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
                'w-full',
              ]"
            >
              <p class="flex">
                Approve Product<CheckIcon
                  class="h-5 w-5 ml-2 text-green-400"
                  aria-hidden="true"
                />
              </p>
            </button>
          </MenuItem>
          <MenuItem v-if="this.isProductUnapprovable" v-slot="{ active }">
            <button
              @click="this.unapproveProduct"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
                'w-full',
              ]"
            >
              <p class="flex">
                Unapprove Product<XIcon
                  class="h-5 w-5 ml-2 text-red-400"
                  aria-hidden="true"
                />
              </p>
            </button>
          </MenuItem>
          <MenuItem v-if="this.isProductMatchable" v-slot="{ active }">
            <button
              @click="this.matchProduct"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
                'w-full',
              ]"
            >
              <p class="flex">
                Match Product<DocumentIcon
                  class="h-5 w-5 ml-2 text-yellow-400"
                  aria-hidden="true"
                />
              </p>
            </button>
          </MenuItem>
          <MenuItem v-if="this.isProductPublishable" v-slot="{ active }">
            <button
              @click="this.publishProduct"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
                'w-full',
              ]"
            >
              <p class="flex">
                Publish Product<ArrowNarrowUpIcon
                  class="h-5 w-5 ml-2 text-green-400"
                  aria-hidden="true"
                />
              </p>
            </button>
          </MenuItem>
          <MenuItem v-if="this.isProductUnPublishable" v-slot="{ active }">
            <button
              @click="this.unpublishProduct"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
                'w-full',
              ]"
            >
              <p class="flex">
                Unpublish Product<StopIcon
                  class="h-5 w-5 ml-2 text-red-400"
                  aria-hidden="true"
                />
              </p>
            </button>
          </MenuItem>
          <MenuItem v-if="this.isProductApprovable" v-slot="{ active }">
            <button
              @click="this.approveMatchAndPublishProduct"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
                'w-full',
              ]"
            >
              <p class="flex">
                Approve, Match & Publish<ChevronDoubleRightIcon
                  class="h-5 w-5 ml-2 text-primary-dark"
                  aria-hidden="true"
                />
              </p>
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ChevronDownIcon,
  EyeIcon,
  CheckIcon,
  XIcon,
  DocumentIcon,
  StopIcon,
  ArrowNarrowUpIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/vue/solid";
import { mapActions } from "vuex";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    EyeIcon,
    CheckIcon,
    XIcon,
    DocumentIcon,
    StopIcon,
    ArrowNarrowUpIcon,
    ChevronDoubleRightIcon,
  },

  props: ["product"],

  computed: {
    isProductApprovable: function () {
      return (
        this.isProductOnline() && this.product.approvalStatus !== "approved"
      );
    },

    isProductUnapprovable: function () {
      return (
        this.isProductOnline() &&
        this.product.approvalStatus !== "refused" &&
        this.product.enabled === false
      );
    },

    isProductMatchable: function () {
      return (
        this.isProductOnline() &&
        this.product.contractStatus === "pending" &&
        this.product.approvalStatus === "approved"
      );
    },

    isProductPublishable: function () {
      return (
        this.isProductOnline() &&
        this.product.contractStatus === "matched" &&
        this.product.approvalStatus === "approved" &&
        this.product.enabled === false
      );
    },

    isProductUnPublishable: function () {
      return (
        this.isProductOnline() &&
        this.product.contractStatus === "matched" &&
        this.product.approvalStatus === "approved" &&
        this.product.enabled === true
      );
    },
  },

  methods: {
    ...mapActions("backoffice", ["executeAction"]),
    ...mapActions("notifications", ["notify"]),

    isProductOnline: function () {
      return this.product.source !== "offline";
    },

    redirectToProductPage: function () {
      this.$router.push(`/products/${this.product.id}`);
    },

    approveProduct: async function () {
      await this.executeAction({
        product: this.product,
        action_type: "approve_products",
      });
    },
    unapproveProduct: async function () {
      await this.executeAction({
        product: this.product,
        action_type: "unapprove_products",
      });
    },
    matchProduct: async function () {
      await this.executeAction({
        product: this.product,
        action_type: "match_products",
      });
    },
    publishProduct: async function () {
      await this.executeAction({
        product: this.product,
        action_type: "publish_products",
      });
    },
    unpublishProduct: async function () {
      await this.executeAction({
        product: this.product,
        action_type: "unpublish_products",
      });
    },
    approveMatchAndPublishProduct: async function () {
      await this.executeAction({
        product: this.product,
        action_type: "approve_match_and_publish_products",
      });
    },
  },
};
</script>
