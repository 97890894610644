<template>
  <div>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          Actions
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute overflow-y-auto right-0 z-10 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto"
        >
          <div class="py-1">
            <MenuItem v-if="this.lead.status === 'live'" v-slot="{ active }">
              <button
                @click="this.disableLead(lead)"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Disable<XIcon
                    class="h-5 w-5 ml-2 text-red-400"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import BackofficeService from "@/api/services/backoffice";
import { ChevronDownIcon, XIcon } from "@heroicons/vue/solid";

export default {
  props: ["lead"],

  components: { Menu, MenuItem, MenuItems, MenuButton, XIcon, ChevronDownIcon },

  methods: {
    disableLead: async function (lead) {
      try {
        await BackofficeService.disableLead(lead.id);
        this.$emit("disabledLead");
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 406:
            this.notify({
              type: "error",
              category: "simple",
              title: "Something went wrong...",
              text: "This lead can't be disabled",
            });
        }
      }
    },
  },
};
</script>
