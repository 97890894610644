<template>
  <div>
    <h1 class="text-3xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
      {{ $t("Declared Claims") }}
    </h1>
  </div>
</template>

<script>
export default {};
</script>
