<template>
  <div class="flex flex-col gap-5">
    <GeneralInformations :organization-informations="generalInformations" />
    <StoresInformations :stores-informations="stores" @open-modal="openStoreConfigurationModal = true" />
    <UsersInformations :users-informations="users" :organization-id="organizationId" />
  </div>
  <ConfigureStoreModal
    v-bind:organization-id="organizationId"
    v-bind:open-modal="this.openStoreConfigurationModal"
    @updateOrganization="fetchOrganization"
    @close="this.openStoreConfigurationModal = false"
  />
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import GeneralInformations from "@/views/organizations/details/GeneralInformations.vue";
import StoresInformations from "@/views/organizations/details/StoresInformations.vue";
import ConfigureStoreModal from "@/views/organizations/ConfigureStoreModal.vue";
import { mapMutations } from "vuex";
import UsersInformations from "@/views/organizations/details/UsersInformations.vue";

export default {
  components: {
    UsersInformations,
    StoresInformations,
    GeneralInformations,
    ConfigureStoreModal,
  },

  async beforeMount() {
    this.organizationId = this.$route.params.id;
    await this.fetchOrganization();
  },

  data() {
    return {
      organizationId: null,
      organization: null,
      openStoreConfigurationModal: false,
    };
  },

  computed: {
    generalInformations() {
      return this.organization?.general_informations;
    },

    stores() {
      return this.organization?.stores;
    },

    users() {
      return this.organization?.users;
    },
  },

  methods: {
    ...mapMutations("backoffice", ["setIsLoading"]),

    async fetchOrganization() {
      if (this.organizationId) {
        let response = null;
        this.setIsLoading(true);

        try {
          response = await BackofficeService.getOrganization(
            this.organizationId
          );
          this.organization = response.data;
        } catch (error) {
          console.log(error);
        } finally {
          this.setIsLoading(false);
        }
      }
    },
  },
};
</script>
