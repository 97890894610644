<template>
  <div class="bg-gray-100 shadow overflow-hidden sm:rounded-lg">
    <div
      @click="toggleCollapse"
      class="flex justify-between items-center px-4 py-5 sm:px-6 cursor-pointer"
    >
      <h3 class="text-lg leading-6 font-bold text-gray-900">
        {{ header }}
      </h3>
      <div class="w-10">
        <ChevronUpIcon v-if="collapsed" />
        <ChevronDownIcon v-else />
      </div>
    </div>
    <div v-if="!collapsed" class="flex flex-col">
      <slot />
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronDownIcon, ChevronUpIcon },

  props: {
    open: {
      type: Boolean,
      default: true,
      required: false,
    },
    header: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      collapsed: false,
    };
  },

  methods: {
    toggleCollapse: function () {
      this.collapsed = !this.collapsed;
    },
  },

  watch: {
    open: {
      immediate: true,
      handler() {
        this.collapsed = !this.open;
      },
    },
  },
};
</script>
