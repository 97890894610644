<template>
  <div>
    <div class="sm:flex-auto"></div>
    <div>
      <p class="text-3xl pl-2 font-extrabold tracking-tight text-gray-900">
        {{ $t("Leads") }}
      </p>
      <div class="mt-8 flow-root">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-1 text-left text-xs font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      class="px-2 py-1 text-left text-xs font-semibold text-gray-900"
                    >
                      Source
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-1 text-left text-xs font-semibold text-gray-900"
                    >
                      Client
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-1 text-left text-xs font-semibold text-gray-900"
                    >
                      Produit
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-1 text-left text-xs font-semibold text-gray-900"
                    >
                      Lien
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-1 text-left text-xs font-semibold text-gray-900"
                    >
                      Promotion
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-1 text-left text-xs font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="lead in leads" :key="lead.id">
                    <td
                      class="whitespace-nowrap px-2 py-1 text-xs font-medium text-gray-900"
                    >
                      <LeadInfoBox
                        class="text-gray-900"
                        v-bind:textToCopy="lead.creationDate"
                      >
                        {{ lead.creationDate }}
                      </LeadInfoBox>
                      <LeadInfoBox
                        class="text-primary-dark"
                        v-bind:textToCopy="lead.elapsedDays"
                      >
                        {{ lead.elapsedDays }}
                        {{ lead.elapsedDays > 1 ? "jours" : "jour" }}
                        <span
                          class="text-red-500"
                          v-if="lead.remainingDays > 0"
                        >
                          {{ lead.remainingDays }}
                          {{ lead.remainingDays > 1 ? "jours" : "jour" }}
                        </span>
                        <span class="text-gray-500 font-bold" v-else
                          >Expiré</span
                        >
                      </LeadInfoBox>
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-1 text-xs text-gray-800"
                    >
                      <LeadInfoBox v-bind:textToCopy="lead.source">
                        {{ lead.source }}
                      </LeadInfoBox>
                      <LeadInfoBox
                        class="ml-4 text-gray-400"
                        v-bind:textToCopy="lead.seller"
                      >
                        {{ lead.seller }}
                      </LeadInfoBox>
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-1 text-xs text-gray-800"
                    >
                      <LeadInfoBox v-bind:textToCopy="lead.email">
                        {{ lead.email }}
                      </LeadInfoBox>
                      <LeadInfoBox
                        class="ml-4 text-gray-400"
                        v-bind:textToCopy="lead.phone"
                      >
                        {{ lead.phone }}
                      </LeadInfoBox>
                    </td>
                    <td class="whitespace-nowrap px-2 text-xs text-gray-800">
                      <LeadInfoBox v-bind:textToCopy="lead.productName">
                        {{ lead.productName }}
                      </LeadInfoBox>
                      <LeadInfoBox
                        class="ml-4 text-gray-400"
                        v-bind:textToCopy="lead.productPrice"
                      >
                        ({{ lead.productPrice }})
                      </LeadInfoBox>
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-1 text-xs text-gray-500"
                    >
                      <LeadInfoBox v-bind:textToCopy="lead.subscriptionLink">
                        <span
                          v-bind:class="[
                            lead.hasOpenedSubscriptionLink
                              ? 'text-bold text-green-500'
                              : 'text-bold text-red-500',
                          ]"
                        >
                          {{
                            lead.hasOpenedSubscriptionLink
                              ? "Ouvert"
                              : "Jamais ouvert"
                          }}
                        </span>
                      </LeadInfoBox>
                    </td>
                    <td>
                      <div class="text-xs text-gray-500">
                        <DiscountButton
                          class="ml-2"
                          v-bind:discounts="discounts"
                          v-bind:lead="lead"
                          @discountUpdated="this.getLeads(currentPage)"
                        />
                      </div>
                    </td>
                    <td>
                      <LeadActionButtons
                        v-bind:lead="lead"
                        @disabledLead="this.getLeads(this.currentPage)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Pagination
    v-bind:currentPage="currentPage"
    v-bind:pages="pages"
    v-bind:startIdx="startIdx"
    v-bind:endIdx="endIdx"
    v-bind:total="this.totalNumberOfLeads"
    v-on:changePage="handleChangePage"
    v-if="pages.length > 1"
  />
</template>

<script>
import { mapState } from "vuex";
import Pagination from "@/components/utils/Pagination.vue";
import BackofficeService from "@/api/services/backoffice";
import LeadInfoBox from "./LeadInfoBox.vue";
import DiscountButton from "@/views/leads/discount/DiscountButton";
import LeadActionButtons from "@/views/leads/LeadActionButtons.vue";

const PAGE_SIZE = 25;

export default {
  components: { LeadActionButtons, LeadInfoBox, Pagination, DiscountButton },

  data() {
    return {
      leads: [],
      currentPage: 1,
      totalNumberOfLeads: 1,
      discounts: [],
    };
  },

  computed: {
    ...mapState("backoffice", ["isLoading"]),
    startIdx: function () {
      return (this.currentPage - 1) * PAGE_SIZE;
    },
    endIdx: function () {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfLeads);
    },
    pages: function () {
      if (this.isLoading || this.totalNumberOfLeads < PAGE_SIZE) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.totalNumberOfLeads / PAGE_SIZE)).keys(),
      ].map((e) => e + 1);
    },
  },

  created() {
    this.getLeads(this.currentPage);
  },

  watch: {
    currentPage(newPage) {
      this.getLeads(newPage);
    },
  },

  methods: {
    async getLeads(page) {
      const response = await BackofficeService.getLeads(page);
      this.leads = response.data.leads;
      this.totalNumberOfLeads = response.data.totalNumberOfLeads;
      this.discounts = [{ Id: null, title: "Aucune" }].concat(
        response.data.discounts
      );
    },
    handleChangePage: function (page) {
      this.currentPage = page;
    },
  },
};
</script>
